import React, { Component } from 'react'
import lrz from 'lrz';
import { BASE_URL ,DOWNFILEURL, Axios } from '../../../utils/url'
import { XSZ_URL ,CLCRSQ_URL,JHZ_URL,SFZ_URL,YKT_URL,XMHT_URL,LDHT_URL} from '../../../utils/fjyl'

import { NavBar, InputItem, List, TextareaItem, Picker, Radio, Button, Toast, ImagePicker, Icon, Modal } from 'antd-mobile';
import './clcrsq.css'
const RadioItem = Radio.RadioItem;

export default class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cllx: '',
      syrlxList: '',
      syrlxText: '',
      crzlxList: [{
        label: '',
        value: '',
      }],
      crzlxText: '',
      cllxtext: '',
      fjList: '',
      syrlx: '',
      cllxList: '',
      crzlx: '',
      clsyr: '',
      cph: '',
      sfsm: '',
      sqr: '',
      sqdw: '',
      sfzh: '',
      zz: '',
      phone: '',
      jtdh: '',
      bgdh: '',
      ektbh: '',
      zrr: '',
      jsdw: '',
      sqsm: '',
      files: [],
      xszfj: false,
      sfzfj: false,
      jhzfj: false,
      clcrsqdfj: false,
      yktfj: false,
      xmhtfj: false,
      ldhtfj: false,
      value: '',
      bglx: "保存",
      modal2: false,
      xszyl: false,
      yktyl:false,
      clcrsqyl:false,
      sfzyl:false,
      jhzyl:false,
      xmhtyl:false,
      ldhtyl:false,
      text: ""
    }
  };



  //进页面加载
  componentDidMount = () => {
    this.getlx();
    // this.queryDwTree();

    console.log(localStorage.getItem("userInfo"));
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    this.setState({
      sqr: userInfo.ryxm,
      sqdw: userInfo.rysx20120716p0026,
      sfzh: userInfo.sfzh,
      zz: userInfo.rysx20120716p0051,
      ektbh: userInfo.rysx20120716p0046,
      sqrid: userInfo.ryid,
      sqdwbm: '',
      phone: userInfo.rysx20120716p0048,
      zrr: userInfo.ryxm,
      userInfo: userInfo,
      dwbm: userInfo.dwbm,

    })
  }
  //获取类型
  getlx = () => {

    const url = BASE_URL + 'bcrzlxbmController/getSyrlxList';
    var params = new URLSearchParams();
    Axios({
      method: 'post',
      url: url,
      data: params
    }).then((res) => {
      console.log(res);
      if (res.status === 200) {
        if(res.data.syrlxList){
          if(res.data.syrlxList.length >0){

            for(var i = 0;i< res.data.syrlxList.length;i++){
              if(res.data.syrlxList[i].label ==="甲方单位"){
                res.data.syrlxList.splice(i, i)
                console.log(res.data.syrlxList);
              }
              if(res.data.syrlxList[i].label ==="乙方单位(免费)"){
                res.data.syrlxList[i].label = "外部单位"
              }
              if(res.data.syrlxList[i].label ==="乙方个人(免费)"){
                res.data.syrlxList[i].label = "外部单位人员"
              }
              if(res.data.syrlxList[i].label ==="居民"){
                res.data.syrlxList[i].label = "员工及家属"
              }
            }
          }
        }
        this.setState({
          syrlxList: res.data.syrlxList,
          cllxList: res.data.cllxList
        });

      }
    });

  }
  //获取单位
  // queryDwTree = () => {
  //   const url = BASE_URL + 'bsqjlController/queryDwTree';
  //   var params = new URLSearchParams();
  //   params.append('dwbm', "CNPC.PetroChina.TLM");
  //   params.append('maxdwjb', "100");
  //   params.append('mindwjb', "0");
  //   params.append('order', "DWPWSX");
  //   Axios({
  //     method: 'post',
  //     url: url,
  //     data: params
  //   }).then((res) => {
  //     console.log(res);
  //     if (res.status == 200) {
  //       // this.setState({
  //       //   arr:res.data
  //       // })
  //       this.setState((state, props) => {
  //         return {
  //           arr: res.data,
  //           arr2: res.data
  //         }
  //       },
  //         () => {

  //         })
  //     }
  //   });
  // }

  //选择所有人类型
  handleFromChange = (e) => {

    const list = this.state.syrlxList

    for (var item in list) {
      if (list[item].value == e) { //item 表示Json串中的属性，如'name'
        var jValue = list[item];//key所对应的value
        // console.log(jValue.label);
        // this.state.syrlxText = jValue.label;
        this.setState({
          syrlxText: jValue.label,
          syrlx: e
      }, () => {
          this.getfjlx();
      });
      }
    }


  }
  async getfjlx() {
    if (this.state.syrlx !== '') {
            const url = BASE_URL + 'brysfxmpzController/getCrzlxList';
            var params = new URLSearchParams();
            params.append('lxbm', this.state.syrlx);
            await Axios({
              method: 'post',
              url: url,
              data: params
            }).then((res) => {
              console.log(res)
              if (res.status == 200) {
                if(this.state.syrlxText ==="员工及家属"){

                  var jmcrz =[];
                   jmcrz.push(res.data.crzList[0])

                  this.setState({
                    crzlxList: jmcrz,
                    fjList: res.data.fjList,
                    fjlxList: res.data.fjlxList
                  })
                  // this.setState((state, props) => {
                  //   return {
                  //       crzlxList: jmcrz,
                  //   fjList: res.data.fjList,
                  //   fjlxList: res.data.fjlxList
                  //   }
                  // },
                  //   () => {
                  //   })
                }else{
                  // this.setState((state, props) => {
                  //   return {
                  //     crzlxList: res.data.crzList,
                  //   fjList: res.data.fjList,
                  //   fjlxList: res.data.fjlxList
                  //   }
                  // },
                  //   () => {
                  //   })
                  this.setState({
                    crzlxList: res.data.crzList,
                    fjList: res.data.fjList,
                    fjlxList: res.data.fjlxList
                  })
                }

              }

              console.log(this.state.fjList.length)
              var cc = this.state.fjList.length;
              const fjList = this.state.fjList;
              console.log(fjList)
              for (var a = 0; a < cc; a++) {
                console.log(fjList[a].fjlxbm);
                if (fjList[a].fjlxbm === "XSZ") {
                  this.setState({
                    xsz: [fjList[a]],
                    xszList: [],
                  })
                  console.log(this.state.xsz);
                } else if (fjList[a].fjlxbm === "XMHT") {
                  this.setState({
                    xmht: [fjList[a]],
                    xmhtList: []
                  })
                } else if (fjList[a].fjlxbm === "CLCRSQD") {
                  this.setState({
                    clcrsqd: [fjList[a]],
                    clcrsqdList: []
                  })
                } else if (fjList[a].fjlxbm === "SFZ") {
                  this.setState({
                    sfz: [fjList[a]],
                    sfzList: []
                  })
                } else if (fjList[a].fjlxbm === "JHZ") {
                  this.setState({
                    jhz: [fjList[a]],
                    jhzList: []
                  })
                } else if (fjList[a].fjlxbm === "LDHT") {
                  this.setState({
                    ldht: [fjList[a]],
                    ldhtList: []
                  })
                } else if (fjList[a].fjlxbm === "YKT") {
                  this.setState({
                    ykt: [fjList[a]],
                    yktList: []
                  })
                }

              }
              // console.log(this.state)

            });


          }
  }
  //选择车辆类型
  handleCllxChange = (e) => {

    const listCllx = this.state.cllxList
    console.log(listCllx)
    for (var item in listCllx) {
      if (listCllx[item].value == e) { //item 表示Json串中的属性，如'name'
        var lValue = listCllx[item];//key所对应的value
        this.state.cllxtext = lValue.label;
      }
    }
    this.setState({
      cllx: e
    })
    this.forceUpdate()
  }
  //选择出入证类型
  handleCrzlxChange = (e) => {


    if (e == 0) {
      Toast.info('请先选择所有人类型')
    } else {
      const listCrzlx = this.state.crzlxList
      for (var item in listCrzlx) {
        if (listCrzlx[item].value == e) { //item 表示Json串中的属性，如'name'
          var cValue = listCrzlx[item];//key所对应的value
          this.state.crzlxText = cValue.label;
        }
      }
      this.setState({
        crzlx: e
      })
    }

    this.forceUpdate()

  }

  checkCph = (e) => {
    const url = BASE_URL + 'bclxxController/checkCph';
    var params = new URLSearchParams();
    params.append('cph', e);
    Axios({
      method: 'post',
      url: url,
      data: params,

    }).then((res) => {
      console.log(res)
      if (res.data.flag === false) {
        // Toast.info(res.data.msg,1,null,false)
        Toast.info(res.data.msg)
        this.setState({
          cph: ''
        })
      }

    });
    this.setState({
      cph: e
    })
  }

  //生成虚拟身份证号
  scsfz = () => {
    const url = BASE_URL + 'bcrzlxbmController/scxnsfzh';
    var params = new URLSearchParams();
    Axios({
      method: 'post',
      url: url,
      data: params
    }).then((res) => {
      console.log(res)
      this.setState({
        sfzh: res.data.sfzh
      })

    });
  }
  getImage = (item, key, fjlxmc) => {
    // const files = this.state.files;
    console.log(item, key, fjlxmc)
    let files = this.state.files;
    console.log(this.state.files);
    console.log(files.length);
    for (var i = 0; i < files.length; i++) {
      console.log()
    }

  }
  onChangeImage = (files01, type, index) => {
    var str=[];
    if(type!="remove"){
        for(let i=0;i<files01.length;i++){

              lrz(files01[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];;
                str.push(arr[0])
                this.setState({
                  xszList: str,
                })
              })
            }
          }else{
              this.setState({
                xszList:files01,
            });
            }

    // if (type === 'add') {
    //   var str=[];
    //   for(let i=0;i<files01.length;i++){

    //     lrz(files01[i].url, { quality: 0.1 })
    // .then((rst) => {
    //   // 处理成功会执行
    //   console.log('压缩成功')

    //   let file = new File([rst.file], files01[i].file.name, { type: files01[i].file.type, lastModified: Date.now() });
    //   // alert(file)
    //   var arr = [{ orientation: 1, url: rst.base64, file: file }];
    //   // arr.push(file);
    //   // alert(JSON.stringify(arr[0]))
    //   str.push(arr[0])

    //   this.setState({
    //     xszList: str,
    //   })
    // })
    // }

    // } else {
    //   this.setState({ xszList: '' })
    // }

  }
  //车辆出入申请附件选择
  onChangeImageClcrsqd = (files01, type, index) => {
    // this.setState({
    //   clcrsqdList: e,
    // });
    console.log(this.state.clcrsqdList)
    if (type != 'remove') {
      var str=[];
      for(let i=0;i<files01.length;i++){

        lrz(files01[i].url, { quality: 0.1 })
    .then((rst) => {
      // 处理成功会执行
      console.log('压缩成功')




      let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
      // alert(file.size)
      var arr = [{ orientation: 1, url: rst.base64, file: file }];
      // arr.push(file);
      str.push(arr[0])
      this.setState({
        clcrsqdList: str,
      })
    })
    }

    } else {
      this.setState({ clcrsqdList: files01 })
    }
  }
  //身份证附件选择
  onChangeImageSfz = (files01, type, index) => {
    // this.setState({
    //   sfzList: e,
    // });
    if (type != 'remove') {
      var str=[];
      for(let i=0;i<files01.length;i++){

        lrz(files01[i].url, { quality: 0.1 })
    .then((rst) => {
      // 处理成功会执行
      console.log('压缩成功')




      let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
      console.log(file)
      var arr = [{ orientation: 1, url: rst.base64, file: file }];
      // arr.push(file);
      str.push(arr[0])

      this.setState({
        sfzList: str,
      })
    })
    }

    } else {
      this.setState({ sfzList: files01 })
    }
  }
  //一卡通附件选择
  onChangeImageYkt = (files01, type, index) => {
    // this.setState({
    //   yktList: e,
    // });
    if (type != 'remove') {
      var str=[];
      for(let i=0;i<files01.length;i++){

        lrz(files01[i].url, { quality: 0.1 })
    .then((rst) => {
      // 处理成功会执行
      console.log('压缩成功')




      let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
      console.log(file)
      var arr = [{ orientation: 1, url: rst.base64, file: file }];
      // arr.push(file);
      str.push(arr[0])

      this.setState({
        yktList: str,
      })
    })
    }

    } else {
      this.setState({ yktList: files01 })
    }
  }
  //项目合同附件选择
  onChangeImageXmht = (files01, type, index) => {
    // this.setState({
    //   xmhtList: e,
    // });
    if (type != 'remove') {
      var str=[];
      for(let i=0;i<files01.length;i++){

        lrz(files01[i].url, { quality: 0.1 })
    .then((rst) => {
      // 处理成功会执行
      console.log('压缩成功')




      let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
      console.log(file)
      var arr = [{ orientation: 1, url: rst.base64, file: file }];
      // arr.push(file);
      str.push(arr[0])

      this.setState({
        xmhtList: str,
      })
    })
    }

    } else {
      this.setState({ xmhtList: files01 })
    }
  }
  //结婚证附件选择
  onChangeImageJhz = (files01, type, index) => {
    // this.setState({
    //   jhzList: e,
    // });
    if (type != 'remove') {
      var str=[];
      for(let i=0;i<files01.length;i++){

        lrz(files01[i].url, { quality: 0.1 })
    .then((rst) => {
      // 处理成功会执行
      console.log('压缩成功')




      let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
      console.log(file)
      var arr = [{ orientation: 1, url: rst.base64, file: file }];
      // arr.push(file);
      str.push(arr[0])

      this.setState({
        jhzList: str,
      })
    })
    }

    } else {
      this.setState({ jhzList: files01 })
    }
  }
  //劳动合同附件选择
  onChangeImageLdht = (files01, type, index) => {
    // this.setState({
    //   ldhtList: e,
    // });
    if (type != 'remove') {
      var str=[];
      for(let i=0;i<files01.length;i++){

        lrz(files01[i].url, { quality: 0.1 })
    .then((rst) => {
      // 处理成功会执行
      console.log('压缩成功')




      let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
      console.log(file)
      var arr = [{ orientation: 1, url: rst.base64, file: file }];
      // arr.push(file);
      str.push(arr[0])

      this.setState({
        ldhtList: str,
      })
    })
    }

    } else {
      this.setState({ ldhtList: files01 })
    }
  }
  setMapList1 = () =>{
    const list = this.state.fjList
    let falg = false;
    let data = {}
    for (var item in list) {
        if (list[item].fjlxid == "6") {
            falg = true;
            data=list[item];
        }
    }
    if (falg) {
      var filesxsz = this.state.xszList;
      if (this.state.xsz != '' && this.state.xsz != undefined) {
        var files = [{
          url:XSZ_URL
        }]
        return(
          <div>
          <div>

          </div>
          <div className="scfj"><span style={{color:"red"}}>*</span>{data.fjlxmc}(<span onClick={()=>this.setState({ xszyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker
            files={filesxsz}
            onChange={this.onChangeImage}
            selectable={filesxsz.length < 1}
            // disableDelete="false"
            capture="camera"
          />
          <div className="line"></div>
          </div>
        )
      }
    }else{
      return null;
    }


  }

  setclcrsqMap1 = () => {
    const list = this.state.fjList
    let falg = false;
    let data = {}
    for (var item in list) {
        if (list[item].fjlxid == "1") {
            falg = true;
            data = list[item]
        }
    }
    if (falg) {
      const fileclcrsqd = this.state.clcrsqdList;
      if (this.state.clcrsqd != '' && this.state.clcrsqd != undefined) {
        var files = [{
          url:CLCRSQ_URL
        }]
        return(
          <div>



          <div className="scfj"><span style={{color:"red"}}>*</span>{data.fjlxmc}(<span onClick={()=>this.setState({ clcrsqyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker
            files={fileclcrsqd}
            onChange={this.onChangeImageClcrsqd}
            selectable={fileclcrsqd.length < 1}
            // disableDelete="false"
            capture="camera"
          />
          <span style={{
                            color:"#888",
                            padding: "15px 15px 9px"
                            }}>1、申请表单直接<span onClick={()=>this.download()} style={{
                            color:"#108ee9",}}>  下载</span></span>
                        <div style={{color:"#888",paddingLeft: "15px",paddingRight: "15px",paddingBottom: "10px"}}>2、宝石花主页-服务大厅表单-车辆出入申请表</div>
          <div className="line"></div>
          </div>
        )
      }
    }else{
      return null;
    }

  }
  genID = (length) => {
    return Number(Math.random().toString().substr(3,length) + Date.now()).toString(36);
}
download =()=>{
  window.open( DOWNFILEURL+'yktsqxx/download?fjlx=clsqdfj')
}

  setsfzMap1 = () => {
    const list = this.state.fjList
    let falg = false;
    let data = {}
    for (var item in list) {
        if (list[item].fjlxid == "2") {
            falg = true;
            data = list[item]
        }
    }
    if (falg) {
      const filesfz = this.state.sfzList;
      if (this.state.sfz != '' && this.state.sfz != undefined) {
        var files = [{
          url:SFZ_URL
        }]
        return(
          <div>
          <div style={{paddingTop: "10px"}}>

          </div>
          <div className="scfj"><span style={{color:"red"}}>*</span>{data.fjlxmc}(<span onClick={()=>this.setState({ sfzyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker
            files={filesfz}
            onChange={this.onChangeImageSfz}
            selectable={filesfz.length < 2}
            // disableDelete="false"
            capture="camera"
          />

          <div className="line"></div>
          </div>
        )
      }
    }else{
      return null;
    }

  }

  setyktMap1 = () => {
    const list = this.state.fjList
    let falg = false;
    let data = {}
    for (var item in list) {
      console.log(list[item].fjlxid)
        if (list[item].fjlxid == "5") {
            falg = true;
            data = list[item]
        }
    }
    if (falg) {
      const yktldht = this.state.yktList;
      if (this.state.ykt != '' && this.state.ykt != undefined) {
        var files = [{
          url:YKT_URL
        }
      ]
        return(
          <div>
          <div style={{paddingTop: "10px"}}>

          </div>
          <div className="scfj"><span style={{color:"red"}}>*</span>{data.fjlxmc}(<span onClick={()=>this.setState({ yktyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker

            files={yktldht}
            onChange={this.onChangeImageYkt}
            selectable={yktldht.length < 1}
            // disableDelete="false"
            capture="camera"
          />
          <div className="line"></div>


          </div>
        )
      }
    }else{
      return null;
    }

  }


  setxmhtMap1 = () => {
    const list = this.state.fjList
    let falg = false;
    let data = {}
    for (var item in list) {
        if (list[item].fjlxid == "8") {
            falg = true;
            data = list[item]
        }
    }
    if (falg) {
      const filexmht = this.state.xmhtList;
      if (this.state.xmht != '' && this.state.xmht != undefined) {
        var files = [{
          url:XMHT_URL
        }]
        return(
          <div>
          <div style={{paddingTop: "10px"}}>

          </div>
          <div className="scfj"><span style={{color:"red"}}>*</span>{data.fjlxmc}(<span onClick={()=>this.setState({ xmhtyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker
          files={filexmht}
          onChange={this.onChangeImageXmht}
          selectable={filexmht.length < 4}
          // disableDelete="false"
          capture="camera"
        />

        <div className="line"></div>
        </div>
        )
      }
    }else{
return null;
    }

  }


  setjhzMap1 = () => {
    const list = this.state.fjList
    let falg = false;
    let data = {}
    for (var item in list) {
        if (list[item].fjlxid == "3") {
            falg = true;
            data = list[item]
        }
    }
    if (falg) {
      const filejhz = this.state.jhzList;
      if (this.state.jhz != '' && this.state.jhz != undefined) {
        var files = [{
          url:JHZ_URL
        }]
        return(
          <div>
          <div style={{paddingTop: "10px"}}>

          </div>
          <div className="scfj">{data.fjlxmc}(<span onClick={()=>this.setState({ jhzyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker
              files={filejhz}
              onChange={this.onChangeImageJhz}
              selectable={filejhz.length < 1}
              // disableDelete="false"
              capture="camera"
            />
            <div className="line"></div>
          </div>
        )
      }
    }else{
      return null;
    }

  }

  setldhtMap1 = () => {
    const list = this.state.fjList
    let falg = false;
    let data = {}
    for (var item in list) {
        if (list[item].fjlxid == "4") {
            falg = true;
            data = list[item]
        }
    }
    if (falg) {
      const fileldht = this.state.ldhtList;
      if (this.state.ldht != '' && this.state.ldht != undefined) {
        var files = [{
          url:LDHT_URL
        }]
        return(
          <div>
          <div style={{paddingTop: "10px"}}>

          </div>
          <div className="scfj"><span style={{color:"red"}}>*</span>{data.fjlxmc}(<span onClick={()=>this.setState({ ldhtyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker
              files={fileldht}
              onChange={this.onChangeImageLdht}
              selectable={fileldht.length < 4}
              // disableDelete="false"
              capture="camera"
            />
            <div className="line"></div>
          </div>
        )
      }
    }else{
      return null;
    }

  }


  //表单提交事件
  handleSubmit = e => {
    //阻止表单提交行为
    e.preventDefault()
    console.log('表单提交')

    // 表单数据
    const { userInfo, dwmc, bglx, syrlx, syrlxText, crzlx, crzlxText, clsyr, cph, cllx, cllxtext, sfsm, sqr, sqdw, sfzh, zz, phone, ektbh, zrr, jsdw, sqsm, sqrid, sqdwbm, xszList, sfzList, yktList, jhzList, xmhtList, ldhtList, clcrsqdList } = this.state
    var newsyrlx = syrlxText;
    if(newsyrlx ==="外部单位"){
      newsyrlx = "乙方单位(免费)";
    }else if(newsyrlx ==="外部单位人员"){
      newsyrlx ="乙方个人(免费)";
    }else if(newsyrlx ==="员工及家属"){
      newsyrlx ="居民";
    }

    const url = BASE_URL + 'bsqjlController/save';
    let params = new FormData()
    params.append('syrlxmc', newsyrlx);
    params.append('syrlxbm', syrlx);
    params.append('crzlxmc', crzlxText);
    params.append('crzlxbm', crzlx);
    params.append('clsyr', clsyr);
    params.append('cph', cph);
    params.append('cllxmc', cllxtext);
    params.append('cllxbm', cllx);
    params.append('sqr', sqr);
    params.append('sqrdw', sqdw);
    params.append('sfzh', sfzh);
    params.append('sqsm', sqsm);
    params.append('zz', zz);
    params.append('sjh', phone);
    params.append('yktbh', ektbh);
    params.append('zrr', zrr);
    params.append('sqrid', sqrid);
    params.append('dwmc', dwmc);
    params.append('dwbm', sqdwbm);
    params.append('bglx', bglx);
    params.append('userinfo', JSON.stringify(userInfo));
    let fileinfo = []
    if (sfzList !== undefined) {

      if (sfzList.length !== 0) {
        for (let f of sfzList) {
          console.log(f)
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '2' });
        }

    }
    }
    if (xmhtList !== undefined) {

      if (xmhtList.length !== 0) {
        for (let f of xmhtList) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '8' });
        }

    }
    }
    if (yktList !== undefined) {

      if (yktList.length !== 0) {
        for (let f of yktList) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '5' });
        }

    }
    }
    if (jhzList !== undefined) {

      if (jhzList.length !== 0) {
        for (let f of jhzList) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '3' });
        }

    }
    }
    if (ldhtList !== undefined) {

      if (ldhtList.length !== 0) {
        for (let f of ldhtList) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '4' });
        }

    }
    }
    if (xszList !== undefined) {

      if (xszList.length !== 0) {
        for (let f of xszList) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '6' });
        }

    }
    }
    if (clcrsqdList !== undefined) {

      if (clcrsqdList.length !== 0) {
        for (let f of clcrsqdList) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '1' });
        }

    }
    }
    params.append('fileinfo', JSON.stringify(fileinfo));

    if (this.state.xsz !== undefined && this.state.xsz[0].sfbt === "必填") {
      if (xszList.length === 0) {
        Toast.info("请上传行驶证附件", 1, null, false)
      } else {
        params.append('xszList', JSON.stringify(xszList));
        this.state.xszfj = true
      }


    } else {
      params.append('xszList', JSON.stringify(xszList));
      this.state.xszfj = true
    }
    if (this.state.jhz !== undefined && this.state.jhz[0].sfbt === "必填") {

      if (jhzList.length === 0) {
        Toast.info("请上传结婚证附件", 1, null, false)

      } else {
        params.append('jhzList', JSON.stringify(jhzList));
        this.state.jhzfj = true
      }

    } else {
      params.append('jhzList', JSON.stringify(jhzList));
      this.state.jhzfj = true
    }
    if (this.state.clcrsqd !== undefined && this.state.clcrsqd[0].sfbt === "必填") {
      if (clcrsqdList.length === 0) {
        Toast.info("请上传车辆出入申请单附件", 1, null, false)
      } else {
        params.append('clcrsqdList', JSON.stringify(clcrsqdList));
        this.state.clcrsqdfj = true
      }

    } else {
      params.append('clcrsqdList', JSON.stringify(clcrsqdList));
      this.state.clcrsqdfj = true
    }
    if (this.state.sfz !== undefined && this.state.sfz[0].sfbt === "必填") {

      if (sfzList.length === 0) {
        Toast.info("请上传身份证附件", 1, null, false)
      } else {
        params.append('sfzList', JSON.stringify(sfzList));
        this.state.sfzfj = true
      }

    } else {
      this.state.sfzfj = true
      params.append('sfzList', JSON.stringify(sfzList));
    }
    if (this.state.ykt !== undefined && this.state.ykt[0].sfbt === "必填") {

      if (yktList.length === 0) {
        Toast.info("请上传一卡通附件", 1, null, false)
      } else {
        params.append('yktList', JSON.stringify(yktList));
        this.state.yktfj = true
      }

    } else {
      this.state.yktfj = true
      params.append('yktList', JSON.stringify(yktList));
    }
    if (this.state.xmht !== undefined && this.state.xmht[0].sfbt === "必填") {
      if (xmhtList.length === 0) {
        Toast.info("请上传项目合同附件", 1, null, false)

      } else {
        params.append('xmhtList', JSON.stringify(xmhtList));
        this.state.xmhtfj = true
      }

    } else {
      this.state.xmhtfj = true
      params.append('xmhtList', JSON.stringify(xmhtList));
    }
    if (this.state.ldht !== undefined && this.state.ldht[0].sfbt === "必填") {

      if (ldhtList.length === 0) {
        Toast.info("请上传劳动合同附件", 1, null, false)
      } else {
        this.state.ldhtfj = true
        params.append('ldhtList', JSON.stringify(ldhtList));
      }

    } else {
      this.state.ldhtfj = true
      params.append('ldhtList', JSON.stringify(ldhtList));
    }

    console.log(zz);
    if(this.state.clcrsqdfj && this.state.jhzfj&& this.state.sfzfj&& this.state.yktfj&& this.state.xmhtfj&& this.state.ldhtfj&& this.state.xszfj && cllxtext && cph &&syrlxText && crzlxText  && clsyr  &&sfzh&&zz&&phone){
    console.log("现在可以提交表单");
    //   console.log(xszList)
    Toast.loading("加载中...", 0, null, true);
    Axios({
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'post',
      url: url,
      data: params,
      timeout: 900000, //这个就是重点
      //这部分非常重要，否则formdata会被转格式
      // transformRequest: [function () {
      //   return params;
      // }],
    }).then((res) => {
      Toast.hide();
      if (res.status == 200 && res.data.msg == "保存成功!") {
        // Toast.info(res.data.msg)
        Toast.success(res.data.msg, 1);
        //
        let _that = this;
        setTimeout( function(){
          _that.props.history.go(-1)
          }, 1000 );
      }
    });
    }
    else{
      Toast.info("请将表单填完再提交")
    }





  }


  onClosexsz = ()=>{
    this.setState({
          xszyl: false
        });
  }
  onClosesfz = ()=>{
    this.setState({
          sfzyl: false
        });
  }
  onClosesfzfm = ()=>{
    this.setState({
          sfzyl: false
        });
  }
  onCloseclcrsq = ()=>{
    this.setState({
          clcrsqyl: false
        });
  }
  onCloseykt = ()=>{
    this.setState({
          yktyl: false
        });
  }
  onCloseygk = ()=>{
    this.setState({
          ygkyl: false
        });
  }
  onClosejhz = ()=>{
    this.setState({
          jhzyl: false
        });
  }
  onClosexmht = ()=>{
    this.setState({
          xmhtyl: false
        });
  }
  onCloseldht = ()=>{
    this.setState({
          ldhtyl: false
        });
  }
  // onClose = key => () => {
  //   this.setState({
  //     arr: this.state.arr2,
  //     [key]: false
  //   });
  // }
  // onChangeDw = (value) => {
  //   this.setState({
  //     clsyr: value,
  //   });
  // };
  ///搜索单位
  // search = (val) => {
  //   console.log(val);
  //   this.forceUpdate()
  //   this.SerMapDw(val)
  //   if (val) {
  //     console.log(val)
  //     const searchResultArr = [];
  //     const arrs = this.state.arr.filter(it => it.label.includes(val));
  //     if (arrs.length > 0) {
  //       this.setState((state, props) => {
  //         return {
  //           text: val,
  //           arr: arrs
  //         }
  //       },
  //         () => {


  //         })
  //     }

  //   }

  //   //

  // }
  // SerMapDw = (e) => {
  //   if (this.state.arr.length > 0) {
  //     return this.state.arr.map(i => (
  //       <RadioItem key={i.value} checked={this.state.value === i.value} onClick={this.onClose('modal2')} onChange={() => this.onChangeDw(i.label, i.value)}>
  //         {i.label}
  //       </RadioItem>
  //     ))
  //   }
  //   this.forceUpdate()
  // }


  render() {
    const entranceCllxOptions = this.state.cllxList
    const clcrsqfj = JSON.stringify(this.state.clcrsqfj)
    const { initData, show, syrlx, crzlx, clsyr, cph, cllx, sfsm, sqr, sqdw, sfzh, zz, phone, jtdh, bgdh, ektbh, zrr, jsdw, sqsm, files } = this.state
    console.log(syrlx);
    console.log(this.state.syrlxList);
    return (

      <div style={{ marginTop: ".89rem" }}>
        {/* 导航栏#0F84D6 */}
        <NavBar
          mode="dark"
          icon={<Icon type="left" />}
          onLeftClick={() => this.props.history.push({
            pathname: './main',
            search: `?isNone='none'`
        })}
        >车辆出入申请</NavBar>

        <form onSubmit={this.handleSubmit}>
          <List renderHeader={() => '车辆信息'} className="my-list">

            <Picker
              data={this.state.syrlxList}
              cols={1}
              onChange={this.handleFromChange}
              className="forss"
              value={syrlx}
              id="aaa"
            >
              <List.Item arrow="horizontal"><span className="error">*</span>所有人类型</List.Item>
            </Picker>

            <Picker
              data={this.state.crzlxList}
              cols={1}
              onChange={this.handleCrzlxChange}
              className="forss"
              value={crzlx}
              extra="请先选择所有人类型"
            >
              <List.Item arrow="horizontal"><span className="error">*</span>出入证类型</List.Item>
            </Picker>
            <InputItem
              placeholder="请输入车牌号"
              ref={el => this.labelFocusInst = el}
              value={cph}
              onChange={(value) => this.setState({ cph: value })}
              onBlur={this.checkCph}
            ><div onClick={() => this.labelFocusInst.focus()}><span className="error">*</span>车牌号</div></InputItem>
            {/* <Button onClick={this.showModal('modal2')}>popup</Button> */}
            <InputItem
              placeholder="请输入车辆所有人"
              ref={el => this.labelFocusInst = el}
              value={clsyr}
              // onFocus={this.showModal('modal2')}
              // onClick={this.showModal('modal2')}
              onChange={(value) => this.setState({ clsyr: value })}

            ><div onClick={() => this.labelFocusInst.focus()}><span className="error">*</span>车辆所有人</div></InputItem>
            {/* <Modal
              popup
              visible={this.state.modal2}
              onClose={this.onClose('modal2')}
              animationType="slide-up"
              style={{
                height: "280px",
                overflow: "auto"
              }}
            // afterClose={() => { alert('afterClose'); }}
            >

              <List renderHeader={() => <div>单位选择</div>} className="popup-list">
                <InputItem
                  placeholder="请输入单位后选择确认"
                  ref={el => this.labelFocusInst = el}
                  onChange={this.search}
                // onChange={(value) => this.setState({ text: value })}
                ></InputItem>
                {this.SerMapDw(this.state.text)}
                <List.Item>
                  <Button type="primary" onClick={this.onClose('modal2')}>确认</Button>
                </List.Item>

              </List>
            </Modal> */}

            <Picker
              data={entranceCllxOptions}
              cols={1}
              onChange={this.handleCllxChange}
              className="forss"
              value={cllx}
            >
              <List.Item arrow="horizontal"><span className="error">*</span>车辆类型</List.Item>
            </Picker>
            {/* <TextareaItem
              title="收费说明"
              placeholder="请填写收费说明"
              data-seed="logId"
              value={sfsm}
              onChange={(value) => this.setState({ sfsm: value })}
              ref={el => this.autoFocusInst = el}
              autoHeight
            /> */}
          </List>
          <List renderHeader={() => '基本信息'} >

            <InputItem
              placeholder="请输入姓名"
              ref={el => this.labelFocusInst = el}
              value={sqr}
              onChange={(value) => this.setState({ sqr: value })}
            ><div onClick={() => this.labelFocusInst.focus()}>申请人</div></InputItem>
            <InputItem
              placeholder="请输入单位"
              ref={el => this.labelFocusInst = el}
              value={sqdw}
              onChange={(value) => this.setState({ sqdw: value })}
            ><div onClick={() => this.labelFocusInst.focus()}>申请单位</div></InputItem>

            <InputItem
              placeholder="请输入身份证号"
              ref={el => this.labelFocusInst = el}
              value={sfzh}
              // extra={(this.ceshi)?<Button type="primary"  size="small" inline onClick={this.scsfz}>生成</Button>:''}
              onExtraClick={this.scsfz}
              onChange={(value) => this.setState({ sfzh: value })}
            ><div onClick={() => this.labelFocusInst.focus()}><span className="error">*</span>身份证号</div>
              {/* <div className="scsfzh"> <button>生成</button> </div>  */}
            </InputItem>
            <InputItem
              placeholder="请输入住址"
              ref={el => this.labelFocusInst = el}
              value={zz}
              onChange={(value) => this.setState({ zz: value })}
            ><div onClick={() => this.labelFocusInst.focus()}><span className="error">*</span>住址</div></InputItem>
            <InputItem
              // {...getFieldProps('phone')}
              type="phone"
              placeholder="请输入手机号"
              value={this.state.phone}
              onChange={(value) => this.setState({ phone: value })}
            ><span className="error">*</span>手机号码</InputItem>

            <InputItem
              placeholder="请输入一卡通编号"
              value={ektbh}
              onChange={(value) => this.setState({ ektbh: value })}
              ref={el => this.labelFocusInst = el}
            ><div onClick={() => this.labelFocusInst.focus()}>一卡通编号</div></InputItem>
            <InputItem
              placeholder="请输入责任人"
              ref={el => this.labelFocusInst = el}
              value={zrr}
              onChange={(value) => this.setState({ zrr: value })}
            ><div onClick={() => this.labelFocusInst.focus()}>责任人</div></InputItem>


            <TextareaItem
              title="申请原因"
              placeholder="请填写申请原因"
              value={sqsm}
              onChange={(value) => this.setState({ sqsm: value })}
              data-seed="logId"
              ref={el => this.autoFocusInst = el}
              autoHeight
            />
            <div className="scfj" style={{fontSize: "14px",
    color: "#888"}}>上传附件</div>
            {this.setMapList1()}
            {/* {this.setMapList()} */}

            <Modal
                    visible={ this.state.xszyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosexsz(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={XSZ_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
            {this.setclcrsqMap1()}
            {/* {this.setclcrsqMap()} */}

            <Modal
                    visible={ this.state.clcrsqyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onCloseclcrsq(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={CLCRSQ_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
            {this.setsfzMap1()}
            {/* {this.setsfzMap()} */}

            <Modal
                    visible={ this.state.sfzyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosesfz(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={SFZ_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>

            {this.setyktMap1()}
            {/* {this.setyktMap()} */}

            <Modal
                    visible={ this.state.yktyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onCloseykt(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={YKT_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>

            {this.setjhzMap1()}
            {/* {this.setjhzMap()} */}

            <Modal
                    visible={ this.state.jhzyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosejhz(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={JHZ_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
            {this.setxmhtMap1()}
            {/* {this.setxmhtMap()} */}

            <Modal
                    visible={ this.state.xmhtyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosexmht(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={XMHT_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
            {this.setldhtMap1()}
            {/* {this.setldhtMap()} */}

            <Modal
                    visible={ this.state.ldhtyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onCloseldht(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={LDHT_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>

          </List>
          <div className="formSubmit">
            <button className="submit" type="submit">
              申 请
            </button>
          </div>
        </form>
      </div>
    )
  }
}
