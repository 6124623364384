import React, { Component } from 'react'
import lrz from 'lrz';
import { BASE_URL ,YL_URL, Axios } from '../../../utils/url'
import Wxzf from '../../../assets/images/wxzf.png'
import Zfbzf from '../../../assets/images/zfbzf.png'
import { XSZ_URL ,CLCRSQ_URL,JHZ_URL,SFZ_URL,YKT_URL,XMHT_URL,LDHT_URL} from '../../../utils/fjyl'

import { NavBar, InputItem, List, TextareaItem, Picker, Radio, Button, Toast, ImagePicker, Icon, Modal } from 'antd-mobile';
import './clcrsq.css'
const RadioItem = Radio.RadioItem;

const Item = List.Item;
const Brief = Item.Brief;
export default class ClcrsqUpdate extends Component {
    constructor(props) {
        super(props);
        var clxx ="";

        if(localStorage.getItem("clxx")){

            clxx = JSON.parse(localStorage.getItem("clxx"));
        }else{
            clxx = this.props.location.query.clxx;
        }
        console.log(clxx)
        // if(this.props.location.query == undefined){
        //     clxx =   localStorage.getItem("clxx")
        // }else{
        //     clxx = this.props.location.query.clxx;
        // }
        // return;





        let isBgBool = false;
        if (clxx.yxdqzt === '已发放') {
            isBgBool = true;
        }
        this.state = {
            cph: clxx.cph,
            clxx: clxx,
            cllcxx: [],
            modal: false,
            id: "",
            isLoaded: false,
            djfisLoaded: false,
            zfdz: "",
            merOrderId: "",
            isPay: false,
            dqzt: clxx.yxdqzt,
            isBg: isBgBool,
            value: "",
            modal2: false,
            bglx:"",
            bglxtext:"",

            showxs:false,
            showqsbg:false,
            xssjh:"",
            cz:clxx.cz,
            sqr:clxx.sqr,
            sqdw:clxx.sqdw,
            sqrsfzh:clxx.sqrsfzh,
            sfzh:clxx.sfzh,
            zz:clxx.zz,
            sjh:clxx.sjh,
            yktbh:clxx.yktbh,
            zrr:clxx.zrr,
            sqsm:clxx.sqbz

        }
    }
    componentDidMount = () => {
        // this.getdjmxList();
        this.getFjList();
        // this.getClLcxx();


    }
    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }
    onChange = (value, label) => {
        this.setState({
            value,
        });
    };
    checkOrder = () => {
        if (this.state.id.je === 0) {

            const url = BASE_URL + 'bjfjlController/save';
            var params = new URLSearchParams();
            //获取车辆缴费信息，传递参数车牌号
            params.append("jfid", this.state.id.jfid);
            params.append("zfzt", "已缴费");
            params.append("jffs", "现金支付");
            params.append("cph", this.state.cph);
            params.append("sl", this.state.clxx.sl);
            params.append("je", this.state.clxx.je);
            params.append("dj", this.state.clxx.dj);
            params.append("jldw", this.state.clxx.jldw);
            params.append("jfxmmc", this.state.clxx.jfxmmc);
            params.append("jfxmid", this.state.clxx.jfxmid);
            params.append("jfje", this.state.clxx.jfje);

            Toast.loading("加载中...", 0, null, true);
            Axios({
                method: 'post',
                url: url,
                data: params
            }).then((res) => {
                Toast.hide();
                if (res.status == 200) {
                    Toast.info("缴费成功", 1, null, false);
                    this.props.history.go(-1)
                }
            });
        } else {
            this.setState({
                modal: true
            });
        }
    }
    //判断是否是微信浏览器的函数
    isWeiXin() {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        var ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    }

    //下单
    handleOrder = () => {

        if (this.state.value === "") {
            Toast.info("请选择支付方式", 1, null, false);
        } else {
            // const url = YL_URL + 'xiadanController/order';
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            var  url ="";
            if(this.isWeiXin()){
                url =YL_URL + 'xiadanWXController/order'
            }else{
                url = YL_URL + 'xiadanController/order';
            }
            var params = new URLSearchParams();
            params.append('totalAmount', this.state.id.je);
            params.append('goodsId', this.state.id.jfid);
           // params.append('goodsName', this.state.id.bz);
           params.append('goodsName', "车辆缴费");
            params.append('id', this.state.id.cph);
            params.append('price', this.state.id.je);
            params.append('payMethod', this.state.value);
            params.append('ywbm', "CLJF");
            params.append('yhid',  userInfo.sfzh);
            params.append('yhxm',  userInfo.ryxm);
            Toast.loading("加载中...", 0, null, true);
            Axios({
                method: 'post',
                url: url,
                data: params
            }).then((res) => {
                Toast.hide();
                if(this.state.value=="WXPay.jsPay"){
                    if (res.status == '200') {
                    window.parent.frames.location.href = res.data.data;
                } else {
                    alert("支付失败，请重新支付");
                }
                }else{
                if (res.status == '200') {
                    let jsonData = JSON.parse(res.data.data);
                    if (jsonData.errCode == 'SUCCESS' && jsonData.xdmsg == "下单成功!") {
                        let payJson = JSON.parse(jsonData.respStr);
                        this.setState((state, props) => {
                            return {
                                zfdz: payJson.appPayRequest.qrCode,
                                merOrderId: payJson.merOrderId
                            }
                        },
                            () => {
                                //支付
                                Modal.alert('确认支付？', '确认支付？', [
                                    { text: '取消', onPress: () => console.log('下次一定') },
                                    {
                                        text: '确定',
                                        onPress: () =>
                                            new Promise((resolve) => {
                                                this.setState({
                                                    isPay: true
                                                });
                                                //跳转到登录
                                                window.open(this.state.zfdz);
                                                window.location.href = this.state.zfdz;
                                                this.props.history.push({ pathname: '/jfqr', query: { merOrderId: this.state.merOrderId } });
                                                setTimeout(resolve, 500);

                                            }),
                                    },
                                ])
                            }
                        )
                    } else {
                        Toast.info("支付失败，请重新支付", 2, null, false)
                    }
                }
            }
            });
        }



    }

    async getFjList(){

        const url = BASE_URL + 'bclcrsqbController/getFjList';
        var params = new URLSearchParams();
        params.append("cph", this.state.clxx.sqid);
        //获取人员身份证号并传递参数
        Toast.loading("加载中...", 0, null, true);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            Toast.hide();
            console.log(res)
            if (res.status == '200') {
                // this.setState({
                //     fjlist: res.data,
                //     isLoaded: true
                // });

                this.setState({
                    fjlist: res.data,
                    isLoaded: true
                },()=>{

                    const list = this.state.fjlist
                    let falg = false;
                    let arr = [];
                    let arr2 = [];
                    let arr3 = [];
                    let arr4 = [];
                    let arr5 = [];
                    let arr6 = [];
                    let arr7 = [];
                    for (var item in list) {
                        if (list[item].fjlb == "2") {
                            falg = true;
                            arr.push(list[item]);
                        }
                        if (list[item].fjlb == "8") {
                            falg = true;
                            arr2.push(list[item]);
                        }
                        if (list[item].fjlb == "5") {
                            falg = true;
                            arr3.push(list[item]);
                        }
                        if (list[item].fjlb == "3") {
                            falg = true;
                            arr4.push(list[item]);
                        }
                        if (list[item].fjlb == "4") {
                            falg = true;
                            arr5.push(list[item]);
                        }
                        if (list[item].fjlb == "6") {
                            falg = true;
                            arr6.push(list[item]);
                        }
                        if (list[item].fjlb == "1") {
                            falg = true;
                            arr7.push(list[item]);
                        }
                    }
                    if(arr.length>0){
                        this.setState({
                            sfzList:arr,
                        })
                    }
                    if(arr2.length>0){
                        this.setState({
                            xmhtList:arr2,
                        })
                    }
                    if(arr3.length>0){
                        this.setState({
                            yktList:arr3,
                        })
                    }
                    if(arr4.length>0){
                        this.setState({
                            jhzList:arr4,
                        })
                    }
                    if(arr5.length>0){
                        this.setState({
                            ldhtList:arr5,
                        })
                    }
                    if(arr6.length>0){
                        this.setState({
                            xszList:arr6,
                        })
                    }
                    if(arr7.length>0){
                        this.setState({
                            clcrsqdList:arr7,
                        })
                    }


                });
            }
        });
    }
    xszfj = () => {
        console.log(this.state.xszList)
        if (this.state.xszList) {
            return (
                <div>
                    <div className="scfj">行驶证</div>
                    <ImagePicker
                        files={this.state.xszList}
                        onChange={this.onChangeImage}
                        selectable={this.state.xszList.length < 1}
                        // disableDelete="false"
                        capture="camera"
                    />
                </div>
            )
        } else {
            return null;

        }
    }
    onChangeImage = (files01, type, index) => {
        var str=[];
        if(type!="remove"){
            for(let i=0;i<files01.length;i++){

                  lrz(files01[i].url, { quality: 0.1 }) .then((rst) => {
                    console.log(rst)
                    let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
                    var arr = [{ orientation: 1, url: rst.base64, file: file }];;
                    str.push(arr[0])
                    this.setState({
                      xszList: str,
                      xszList1: str,
                    })
                  })
                }
              }else{
                  this.setState({
                    xszList:files01,
                });
                }


      }
    jhzfj = () => {
        console.log(this.state.jhzList);
        if (this.state.jhzList) {
            return (
                <div>
                    <div className="scfj">结婚证</div>
                    <ImagePicker
                        files={this.state.jhzList}
                        onChange={this.onChangeImageJhz}
                        selectable={this.state.jhzList.length < 1}
                        // disableDelete="false"
                        capture="camera"
                    />
                </div>
            )
        } else {
            return null;

        }
    }
     //结婚证附件选择
  onChangeImageJhz = (files01, type, index) => {
    // this.setState({
    //   jhzList: e,
    // });
    if (type != 'remove') {
      var str=[];
      for(let i=0;i<files01.length;i++){

        lrz(files01[i].url, { quality: 0.1 })
    .then((rst) => {
      // 处理成功会执行
      console.log('压缩成功')




      let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
      console.log(file)
      var arr = [{ orientation: 1, url: rst.base64, file: file }];
      // arr.push(file);
      str.push(arr[0])

      this.setState({
        jhzList: str,
        jhzList1: str,
      })
    })
    }

    } else {
      this.setState({ jhzList: files01 })
    }
  }
    sfzfj = () => {

        if (this.state.sfzList) {
            return (
                <div>
                    <div className="scfj">身份证</div>
                    <ImagePicker
                        files={this.state.sfzList}
                        selectable={this.state.sfzList.length < 2}
                        // disableDelete="false"
                        capture="camera"
                        onChange={this.onChangeImageSfz}
                    />
                </div>
            )
        } else {
            return null;

        }
    }
    //身份证附件选择
  onChangeImageSfz = (files01, type, index) => {
    // this.setState({
    //   sfzList: e,
    // });
    if (type != 'remove') {
      var str=[];
      for(let i=0;i<files01.length;i++){

        lrz(files01[i].url, { quality: 0.1 })
    .then((rst) => {
      // 处理成功会执行
      console.log('压缩成功')

      let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
      console.log(file)
      var arr = [{ orientation: 1, url: rst.base64, file: file }];
      // arr.push(file);
      str.push(arr[0])

      this.setState({
        sfzList: str,
        sfzList1: str,
      })
    })
    }

    } else {
      this.setState({ sfzList: files01 })
    }
  }
    clcrsqdfj = () => {
        console.log(this.state.clcrsqdList);
        if (this.state.clcrsqdList) {
            return (
                <div>
                    <div className="scfj">车辆出入申请单</div>

                    <ImagePicker
                        files={this.state.clcrsqdList}
                        onChange={this.onChangeImageClcrsqd}
                        selectable={this.state.clcrsqdList.length < 1}
                        // disableDelete="true"
                        capture="camera"
                    />
                </div>
            )
        } else {
            return null;

        }
    }
    //车辆出入申请附件选择
  onChangeImageClcrsqd = (files01, type, index) => {
    // this.setState({
    //   clcrsqdList: e,
    // });
    console.log(this.state.clcrsqdList)
    if (type != 'remove') {
      var str=[];

      for(let i=0;i<files01.length;i++){

        lrz(files01[i].url, { quality: 0.1 })
    .then((rst) => {
      // 处理成功会执行
      console.log('压缩成功')




      let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
      // alert(file.size)
      var arr = [{ orientation: 1, url: rst.base64, file: file }];
      // arr.push(file);
      str.push(arr[0])
      console.log(str)
      this.setState({
        clcrsqdList: str,
        clcrsqdList1: str,
      })
    })
    }
      console.log(this.state.clcrsqdList)
    } else {
      this.setState({ clcrsqdList: files01 })
    }
  }
  genID = (length) => {
    return Number(Math.random().toString().substr(3,length) + Date.now()).toString(36);
}
    ldhtfj = () => {
        console.log(this.state.ldhtList1);
        if (this.state.ldhtList) {
            return (
                <div>
                    <div className="scfj">劳动合同</div>
                    <ImagePicker
                        files={this.state.ldhtList}
                        onChange={this.onChangeImageLdht}
                        selectable={this.state.ldhtList.length < 4}
                        // disableDelete="true"
                        capture="camera"
                    />
                </div>
            )
        } else {
            return null;

        }
    }
    //劳动合同附件选择
  onChangeImageLdht = (files01, type, index) => {
    // this.setState({
    //   ldhtList: e,
    // });
    if (type != 'remove') {
      var str=[];
      for(let i=0;i<files01.length;i++){

        lrz(files01[i].url, { quality: 0.1 })
    .then((rst) => {
      // 处理成功会执行
      console.log('压缩成功')




      let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
      console.log(file)
      var arr = [{ orientation: 1, url: rst.base64, file: file }];
      // arr.push(file);
      str.push(arr[0])

      this.setState({
        ldhtList: str,
        ldhtList1:str
      })
    })
    }

    } else {
      this.setState({ ldhtList: files01 })
    }
  }
    xmhtfj = () => {

        if (this.state.xmhtList) {
            return (
                <div>
                    <div className="scfj">项目合同</div>
                    <ImagePicker
                        files={this.state.xmhtList}
                        onChange={this.onChangeImageXmht}
                        selectable={this.state.xmhtList.length < 4}
                        // disableDelete="true"
                        capture="camera"
                    />
                </div>
            )
        } else {
            return null;

        }
    }
    //项目合同附件选择
  onChangeImageXmht = (files01, type, index) => {
    // this.setState({
    //   xmhtList: e,
    // });
    if (type != 'remove') {
      var str=[];
      for(let i=0;i<files01.length;i++){

        lrz(files01[i].url, { quality: 0.1 })
    .then((rst) => {
      // 处理成功会执行
      console.log('压缩成功')




      let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
      console.log(file)
      var arr = [{ orientation: 1, url: rst.base64, file: file }];
      // arr.push(file);
      str.push(arr[0])

      this.setState({
        xmhtList: str,
        xmhtList1:str
      })
    })
    }

    } else {
      this.setState({ xmhtList: files01 })
    }
  }
    yktfj = () => {

        if (this.state.yktList) {
            return (
                <div>
                    <div className="scfj">一卡通或员工卡</div>
                    <ImagePicker
                        files={this.state.yktList}
                        selectable={this.state.yktList.length < 1}
                        // disableDelete="true"
                        capture="camera"
                        onChange={this.onChangeImageYkt}
                    />
                </div>
            )
        } else {
            return null;

        }
    }
    //一卡通附件选择
  onChangeImageYkt = (files01, type, index) => {
    // this.setState({
    //   yktList: e,
    // });
    if (type != 'remove') {
      var str=[];
      for(let i=0;i<files01.length;i++){

        lrz(files01[i].url, { quality: 0.1 })
    .then((rst) => {
      // 处理成功会执行
      console.log('压缩成功')




      let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
      console.log(file)
      var arr = [{ orientation: 1, url: rst.base64, file: file }];
      // arr.push(file);
      str.push(arr[0])

      this.setState({
        yktList: str,
        yktList1:str
      })
    })
    }

    } else {
      this.setState({ yktList: files01 })
    }
  }

    setLcList = () => {
        if (!this.state.isLoaded) {
            return null;
        } else {
            return this.state.cllcxx.map((item, key) => (
                <Item multipleLine extra={item.dqzt} key={key} >
                    {item.jyhxm} <Brief>{item.sqr}</Brief>
                    <Brief>{item.shyj}</Brief>
                </Item>
            ))
        }

    }
    thyj =() =>{
        console.log(this.state.clxx.yxdqzt)
        if(this.state.clxx.yxdqzt =="手机退回"){
            return (
                <InputItem
                    type="money"
                    value={this.state.clxx.thyy}
                    editable={false}
                ><div>退回原因</div></InputItem>
            )
        }
    }
    setjfxxzt = () => {
        console.log(this.state.dqzt);
        if (!this.state.djfisLoaded) {
            return null;
        } else {
            console.log(this.state.id);
            if (this.state.id !== undefined) {

                if (this.state.dqzt === "待审核") {
                    return null;
                } else if (this.state.dqzt === "已缴费" || this.state.dqzt === "已发放") {
                    return <div> <List renderHeader={() => '缴费信息'} >
                        <InputItem
                            type="money"
                            value={this.state.id.cph}
                            editable={false}
                        ><div>车牌号</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.id.bz}
                            editable={false}
                        ><div>收费项目</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.id.je + "元"}
                            editable={false}
                        ><div>应收总金额</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.id.dqzt}
                            editable={false}
                        ><div>当前状态</div></InputItem>
                    </List>
                    </div>
                } else if (this.state.dqzt === "已审核") {
                    return <div>
                        <List renderHeader={() => '缴费信息'} >
                            <InputItem
                                type="money"
                                value={this.state.id.cph}
                                editable={false}
                            ><div>车牌号</div></InputItem>
                            <InputItem
                                type="money"
                                value={this.state.id.bz}
                                editable={false}
                            ><div>收费项目</div></InputItem>
                            <InputItem
                                type="money"
                                value={this.state.id.je + "元"}
                                editable={false}
                            ><div>应收总金额</div></InputItem>
                            <InputItem
                                type="money"
                                value={this.state.id.dqzt}
                                editable={false}
                            ><div>当前状态</div></InputItem>
                        </List>
                        <button className="submit" type="submit" onClick={this.checkOrder}>
                            支付
                        </button>
                    </div>
                }
            }

        }

    }

    //弹窗
    showModal = key => (e) => {
        e.preventDefault(); // 修复 Android 上点击穿透
        this.setState({
          [key]: true,
        });
      }
      onClose = key => () => {
        this.setState({
          [key]: false,
        });
      }



//表单提交事件
handleSubmit = e => {
    //阻止表单提交行为
    e.preventDefault()
    console.log('表单提交')

    // 表单数据
    const { userInfo, dwmc, bglx, syrlx, syrlxText, crzlx, crzlxText, clsyr, cph, cllx, cllxtext, sfsm, sqr, sqdw, sfzh, zz, phone, ektbh, zrr, jsdw, sqsm, sqrid, sqdwbm, xszList, sfzList, yktList, jhzList, xmhtList, ldhtList, clcrsqdList } = this.state


    const url = BASE_URL + 'bsqjlController/update';
    let params = new FormData()

    params.append('clsyr', this.state.cz);
    params.append('cph', this.state.clxx.cph);
    params.append('sqid', this.state.clxx.sqid);
    params.append('sqr', this.state.sqr);
    params.append('sqdw', this.state.sqdw);
    params.append('sqrsfzh', this.state.sqrsfzh);
    params.append('czsfzh', this.state.sfzh);
    params.append('sqsm', this.state.sqsm);
    params.append('zz', this.state.zz);
    params.append('sjh', this.state.sjh);
    params.append('yktbh', this.state.yktbh);
    params.append('zrr', this.state.zrr);
    params.append('sqrid', sqrid);
    params.append('dwmc', this.state.sqdw);
    params.append('dwbm', sqdwbm);
    params.append('bglx', "修改");
    params.append('userinfo', JSON.stringify(userInfo));
    let fileinfo = []
    if (sfzList !== undefined && this.state.sfzList1 !== undefined) {
      // for (var i = 0; i < sfzList.length; i++) {
      //   params.append("sfzfjList", sfzList[i].file);
      // }
      if (this.state.sfzList1.length !== 0) {
        for (let f of this.state.sfzList1) {
            console.log(f)
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '2' });
        }
        // params.append('fileinfo', JSON.stringify(fileinfo));
    }
    }
    if (xmhtList !== undefined && this.state.xmhtList1 !== undefined) {
      // for (var i = 0; i < xmhtList.length; i++) {
      //   params.append("xmhtfjList", xmhtList[i].file);
      // }
      if (this.state.xmhtList1.length !== 0) {
        for (let f of this.state.xmhtList1) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '8' });
        }
        // params.append('fileinfo', JSON.stringify(fileinfo));
    }
    }
    if (yktList !== undefined && this.state.yktList1 !== undefined) {
      // for (var i = 0; i < yktList.length; i++) {
      //   params.append("yktfjList", yktList[i].file);
      // }
      if (this.state.yktList1.length !== 0) {
        for (let f of this.state.yktList1) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '5' });
        }
        // params.append('fileinfo', JSON.stringify(fileinfo));
    }
    }
    if (jhzList !== undefined && this.state.jhzList1 !== undefined) {
      // for (var i = 0; i < jhzList.length; i++) {
      //   params.append("jhzfjList", jhzList[i].file);
      // }
      if (this.state.jhzList1.length !== 0) {
        for (let f of this.state.jhzList1) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '3' });
        }
        // params.append('fileinfo', JSON.stringify(fileinfo));
    }
    }
    if (ldhtList !== undefined && this.state.ldhtList1 !== undefined) {
      // for (var i = 0; i < ldhtList.length; i++) {
      //   params.append("ldhtfjList", ldhtList[i].file);
      // }
      if (this.state.ldhtList1.length !== 0) {
        for (let f of this.state.ldhtList1) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '4' });
        }
        // params.append('fileinfo', JSON.stringify(fileinfo));
    }
    }
    if (xszList !== undefined && this.state.xszList1 !== undefined) {
      // for (var i = 0; i < xszList.length; i++) {
      //   params.append("xszfjList", xszList[i].file);
      // }
      if (this.state.xszList1.length !== 0) {
        for (let f of this.state.xszList1) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '6' });
        }
        // params.append('fileinfo', JSON.stringify(fileinfo));
    }
    }
    if (clcrsqdList !== undefined && this.state.clcrsqdList1 !== undefined) {
      // for (var i = 0; i < clcrsqdList.length; i++) {
      //   params.append("clcrsqdfjList", clcrsqdList[i].file);
      //   console.log(clcrsqdList[i].file)
      // }
      if (this.state.clcrsqdList1.length !== 0) {
        for (let f of this.state.clcrsqdList1) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '1' });
        }
        // params.append('fileinfo', JSON.stringify(fileinfo));
    }
    }
    params.append('fileinfo', JSON.stringify(fileinfo));

    if (this.state.xszList !== undefined ) {
      if (xszList.length === 0) {
        Toast.info("请上传行驶证附件", 1, null, false)
      } else {
        params.append('xszList', JSON.stringify(this.state.xszList1));
        this.state.xszfj = true
      }


    } else {
      params.append('xszList', JSON.stringify(xszList));
      this.state.xszfj = true
    }
    if (this.state.jhzList !== undefined) {

      if (jhzList.length === 0) {
        Toast.info("请上传结婚证附件", 1, null, false)

      } else {
        params.append('jhzList', JSON.stringify(this.state.jhzList1));
        this.state.jhzfj = true
      }

    } else {
      params.append('jhzList', JSON.stringify(jhzList));
      this.state.jhzfj = true
    }
    if (this.state.clcrsqdList !== undefined) {
      if (clcrsqdList.length === 0) {
        Toast.info("请上传车辆出入申请单附件", 1, null, false)
      } else {
        params.append('clcrsqdList', JSON.stringify(this.state.clcrsqdList1));
        this.state.clcrsqdfj = true
      }

    } else {
      params.append('clcrsqdList', JSON.stringify(clcrsqdList));
      this.state.clcrsqdfj = true
    }
    if (this.state.sfzList !== undefined ) {

      if (sfzList.length === 0) {
        Toast.info("请上传身份证附件", 1, null, false)
      } else {
        params.append('sfzList', JSON.stringify(this.state.sfzList1));
        this.state.sfzfj = true
      }

    } else {
      this.state.sfzfj = true
      params.append('sfzList', JSON.stringify(sfzList));
    }
    if (this.state.yktList !== undefined ) {

      if (yktList.length === 0) {
        Toast.info("请上传一卡通附件", 1, null, false)
      } else {
        params.append('yktList', JSON.stringify(this.state.yktList1));
        this.state.yktfj = true
      }

    } else {
      this.state.yktfj = true
      params.append('yktList', JSON.stringify(yktList));
    }
    if (this.state.xmhtList !== undefined ) {
      if (xmhtList.length === 0) {
        Toast.info("请上传项目合同附件", 1, null, false)

      } else {
        params.append('xmhtList', JSON.stringify(this.state.xmhtList1));
        this.state.xmhtfj = true
      }

    } else {
      this.state.xmhtfj = true
      params.append('xmhtList', JSON.stringify(xmhtList));
    }
    if (this.state.ldhtList !== undefined ) {

      if (ldhtList.length === 0) {
        Toast.info("请上传劳动合同附件", 1, null, false)
      } else {
        this.state.ldhtfj = true
        params.append('ldhtList', JSON.stringify(this.state.ldhtList1));
      }

    } else {
      this.state.ldhtfj = true
      params.append('ldhtList', JSON.stringify(ldhtList));
    }
    console.log(fileinfo)
    console.log(cllxtext);
    console.log(cph);
    console.log(syrlxText);
    console.log(crzlxText);
    console.log(clsyr);
    console.log(phone)
    console.log(sfzh);
    console.log(zz);

    // console.log(params);return;
    if(this.state.clcrsqdfj && this.state.jhzfj&& this.state.sfzfj&& this.state.yktfj&& this.state.xmhtfj&& this.state.ldhtfj&& this.state.xszfj && this.state.cz && this.state.sqr &&this.state.sqdw && this.state.sqrsfzh  &&this.state.sfzh&&this.state.zz&&this.state.sjh&&this.state.yktbh&&this.state.zrr){
    console.log("现在可以提交表单")
    //   console.log(xszList)
    // return;
    Toast.loading("加载中...", 0, null, true);
    Axios({
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'post',
      url: url,
      data: params,
      timeout: 900000, //这个就是重点
      //这部分非常重要，否则formdata会被转格式
    //   transformRequest: [function () {
    //     return params;
    //   }],
    }).then((res) => {
      Toast.hide();
      console.log(res);
      if (res.status == 200 && res.data.msg == "修改成功!") {
        // Toast.info(res.data.msg)
        Toast.success(res.data.msg, 1);
        //
        let _that = this;
        setTimeout( function(){
          _that.props.history.go(-1)
          }, 1000 );
      }
    });
    }
    else{
      Toast.info("请将表单填完再提交")
    }

  }

    render() {

        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}

                    onLeftClick={() => this.props.history.go(-1)}
                >车辆出入申请修改</NavBar>
                <form onSubmit={this.handleSubmit}>
                <div className="divmagin">

                        <div>
                            {this.setjfxxzt()}
                            <List renderHeader={() => '车辆信息'}>

                            <InputItem
                                    type="money"
                                    value={this.state.clxx.yxdqzt}
                                    editable={false}
                                ><div>当前状态</div></InputItem>
                                {this.thyj()}
                                <InputItem
                                    type="money"
                                    value={this.state.clxx.syrlx}
                                    editable={false}
                                ><div>所有人类型</div></InputItem>
                                <InputItem
                                    type="money"
                                    value={this.state.clxx.crzlx}
                                    editable={false}
                                ><div>出入证类型</div></InputItem>
                                <InputItem
                                    type="money"
                                    value={this.state.clxx.cph}
                                    editable={false}
                                ><div>车牌号</div></InputItem>
                                <InputItem
                                    type="text"
                                    value={this.state.cz}
                                    // editable={false}
                                    onChange={(value) => this.setState({ cz: value })}
                                ><div>车辆所有人</div></InputItem>

                                <InputItem
                                    type="money"
                                    value={this.state.clxx.cllx}
                                    editable={false}
                                ><div>车辆类型</div></InputItem>


                            </List>
                            <List renderHeader={() => '基本信息'} >
                                <InputItem
                                    type="text"
                                    value={this.state.sqr}
                                    // editable={false}
                                    ref={el => this.labelFocusInst = el}
                                    onChange={(value) => this.setState({ sqr: value })}
                                ><div>申请人</div></InputItem>
                                <InputItem
                                    type="text"
                                    value={this.state.sqdw}
                                    // editable={false}
                                    onChange={(value) => this.setState({ sqdw: value })}
                                ><div>申请单位</div></InputItem>
                                <InputItem
                                    type="text"
                                    value={this.state.sqrsfzh}
                                    onChange={(value) => this.setState({ sqrsfzh: value })}
                                    // editable={false}
                                ><div>申请身份证</div></InputItem>
                                <InputItem
                                    type="text"
                                    value={this.state.sfzh}
                                    // editable={false}
                                    onChange={(value) => this.setState({ sfzh: value })}
                                ><div>车主身份证</div></InputItem>
                                <InputItem
                                    type="text"
                                    value={this.state.zz}
                                    // editable={false}
                                    onChange={(value) => this.setState({ zz: value })}
                                ><div>住址</div></InputItem>

                                <InputItem
                                    type="text"
                                    value={this.state.sjh}
                                    // editable={false}
                                    onChange={(value) => this.setState({ sjh: value })}
                                ><div>手机号码</div></InputItem>

                                <InputItem
                                    type="text"
                                    value={this.state.yktbh}
                                    // editable={false}
                                    onChange={(value) => this.setState({ yktbh: value })}
                                ><div>一卡通编号</div></InputItem>
                                <InputItem
                                    type="text"
                                    value={this.state.zrr}
                                    // editable={false}
                                    onChange={(value) => this.setState({ zrr: value })}
                                ><div>责任人</div></InputItem>

                                <TextareaItem
                                    title="申请说明"
                                    data-seed="logId"
                                    value={this.state.sqsm}
                                    ref={el => this.autoFocusInst = el}
                                    onChange={(value) => this.setState({ sqsm: value })}
                                    autoHeight
                                />

                            </List>
                            <List renderHeader={() => '附件信息'} >
                                {this.xszfj()}
                                {this.sfzfj()}
                                {this.clcrsqdfj()}
                                {this.jhzfj()}
                                {this.yktfj()}
                                {this.xmhtfj()}
                                {this.ldhtfj()}
                            </List>
                        </div>
                        <div className="divmagin">
                            {this.setLcList()}
                        </div>

                </div>
                <div className="formSubmit">
            <button className="submit" type="submit">
              提 交
            </button>
          </div>
                </form>

            </div>
        )
    }
}
