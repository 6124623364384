import React, { Component } from 'react'
import lrz from 'lrz';
import { BASE_URL, Axios} from '../../../utils/url'


import { NavBar, InputItem, List, TextareaItem, Picker, Radio, Button, Toast, ImagePicker, Icon, DatePicker } from 'antd-mobile';
import './clcrsq.css'
const RadioItem = Radio.RadioItem;
const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);
// GMT is not currently observed in the UK. So use UTC now.
const utcNow = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));

// Make sure that in `time` mode, the maxDate and minDate are within one day.
let minDate = new Date(nowTimeStamp - 1e7);
const maxDate = new Date(nowTimeStamp + 1e7);
// console.log(minDate, maxDate);
if (minDate.getDate() !== maxDate.getDate()) {
  // set the minDate to the 0 of maxDate
  minDate = new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate());
}

export default class Clwg extends Component {
  constructor(props) {
    super(props)
    console.log(props);
    const openid = this.props.location.search;
    var openid1 ="";
    if(openid.length>0){
      var open = openid.split("&");
        var openidArr = open[0].split("=");
        openid1 = openidArr[1];
    }
    this.state = {
      openid:openid1,
      // wglist: [{value: '01', label: '乱停乱放'},{value: '02', label: '不礼让行人'},{value: '03', label: '强超强会'},{value: '04', label: '其他行为'}],
      wglx:"",
      wgbm:"",
      cph:"",
      date: now,
      wgyy:"",
      fjList:[],
      clwgfj: false,
      wglxpd:false,
      phone:""
    }

  };
  //进页面加载
  componentDidMount = () => {
    document.title="手机随手拍";
    console.log("tiis is localStorage",localStorage.getItem("userInfo"));
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo === null) {
        const openid = this.props.location.search;
        if (openid.length > 0) {
            var open = openid.split("&");
            var openidArr = open[0].split("=");
            var openid1 = openidArr[1];
            var params = new URLSearchParams();
            localStorage.setItem("openID", openid1);
            params.append('openid', openid1);
            Axios({
                method: 'post',
                url: BASE_URL + "bRyzcController/getUserInfo",
                data: params
            }).then((res) => {
                localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
                userInfo = JSON.parse(localStorage.getItem("userInfo"));
                this.setState({
                    yhxm: userInfo.ryxm,
                    yhid: userInfo.sfzh
                })
                this.getWglx();
            });
        }
    } else {
      this.setState({
        yhxm: userInfo.ryxm,
        yhdwbm: userInfo.dwbm,
        sfzh: userInfo.sfzh,
      })
      this.getWglx();
    }

    // this.setState({
    //   yhxm: userInfo.ryxm,
    //   yhdwbm: userInfo.dwbm,
    //   sfzh: userInfo.sfzh,})
    // this.getWglx();
  }
  getWglx = () =>{
    const url = BASE_URL + 'bsqjlController/getWglx';
    var params = new URLSearchParams();
    Axios({
      method: 'post',
      url: url,
      data: params
    }).then((res) => {
      console.log(res.data);
      if (res.status === 200) {
        this.setState({
          wglist: res.data,

        });
      }
    });
  }
  //选择违规类型
  handleCllxChange = (e) => {

    const listCllx = this.state.wglist
    console.log(listCllx)
    for (var item in listCllx) {
      if (listCllx[item].value == e) { //item 表示Json串中的属性，如'name'
        var lValue = listCllx[item];//key所对应的value
        this.state.wglx = lValue.label;
      }
    }
    this.setState({
      wgbm: e,
      wglxpd:true
    })
    this.forceUpdate()
  }
  //违规照片
  setMapList = () =>{

        return(

          <ImagePicker
            files={this.state.fjList}
            onChange={this.onChangeImage}
            selectable={this.state.fjList.length < 8}
            // disableDelete="false"
          />

        )

  }
  onChangeImage = (files01, type, index) => {
    var str=[];
    if(type!="remove"){
        for(let i=0;i<files01.length;i++){

              lrz(files01[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];;
                str.push(arr[0])
                this.setState({
                  fjList: str,
                })
              })
            }
          }else{
              this.setState({
                fjList:files01,
            });
            }
          }
          genID = (length) => {
            return Number(Math.random().toString().substr(3,length) + Date.now()).toString(36);
        }
        p = (s) =>{
          return s < 10 ? '0' + s : s
        }
        //表单提交事件
  handleSubmit = e => {
    //阻止表单提交行为
    e.preventDefault()
    console.log('表单提交')
    const d = new Date(this.state.date);
    const resDate = d.getFullYear() + '-' + this.p((d.getMonth() + 1)) + '-' + this.p(d.getDate())
const resTime = this.p(d.getHours()) + ':' + this.p(d.getMinutes()) + ':' + this.p(d.getSeconds())
const pzsj = resDate+" "+resTime;
  console.log(resDate+" "+resTime);
  console.log(resTime);
  let thisTime = pzsj;
  thisTime = thisTime.replace(/-/g, '/');
  let time = new Date(thisTime);
  time = time.getTime();
  console.log(time)


    const url = BASE_URL + 'bsqjlController/saveClwg';
    let params = new FormData()
    params.append('wglx', this.state.wglx);
    params.append('wgbm', this.state.wgbm);
    params.append('cph', this.state.cph);
    params.append('wgsj', time);
    params.append('wgxq', this.state.wgyy);
    params.append('yhxm', this.state.yhxm);
    params.append('yhdwbm', this.state.yhdwbm);
    params.append('sfzh', this.state.sfzh);
    params.append('phone', this.state.phone);
    params.append('openid', this.state.openid);
    console.log(this.state.phone);
    let fileinfo = []
    if (this.state.fjList !== undefined) {

      if (this.state.fjList.length !== 0) {
        params.append('clwgList', JSON.stringify(this.state.fjList));
        this.state.clwgfj = true;
        for (let f of this.state.fjList) {
          console.log(f)
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '1' });
        }

    }else{
      Toast.info("请上传车辆违规照片", 1, null, false)
    }
    }




    if(this.state.wglxpd && this.state.clwgfj){
    console.log("现在可以提交表单");
    //   console.log(xszList)

    Toast.loading("加载中...", 0, null, true);
    Axios({
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'post',
      url: url,
      data: params,
      timeout: 900000, //这个就是重点
      //这部分非常重要，否则formdata会被转格式
      // transformRequest: [function () {
      //   return params;
      // }],
    }).then((res) => {
      console.log(res);
      Toast.hide();
      if (res.status == 200 && res.data.msg == "提交成功!") {
        // Toast.info(res.data.msg)
        Toast.success(res.data.msg, 1);
        //
        let _that = this;
        setTimeout( function(){
          window.location.reload();
          }, 1000 );


      }
    });
    }
    else{
      Toast.info("请将表单填完再提交")
    }





  }
  render() {
    const entranceCllxOptions = this.state.wglist
    return (
        <div>

            {/* 导航栏#0F84D6 */}
            {/* <NavBar
                mode="dark"


            >手机随手拍</NavBar> */}

            <form onSubmit={this.handleSubmit}>
            <List renderHeader={() => ''} className="my-list1">
            <div style={{fontSize: "14px",color:"rgb(243 75 98)",textAlign:"center",lineHeight:"25px"}} >注：我们将根据违章事实和举报先后顺序给予奖励，若不留联系方式，视为匿名举报，不纳入奖励范围。</div>
            <div className="scfj" style={{fontSize: "14px",fontWeight:"800",
    color: "#888"}}><span className="error">*</span>违规照片</div>
    {this.setMapList()}
            <Picker
              data={entranceCllxOptions}
              cols={1}
              onChange={this.handleCllxChange}
              className="forss"
              value={this.state.wgbm}
            >
              <List.Item arrow="horizontal"><span className="error">*</span>违规类型</List.Item>
            </Picker>
            <InputItem
              placeholder="请输入车牌号"
              ref={el => this.labelFocusInst = el}
              value={this.state.cph}
              onChange={(value) => this.setState({ cph: value })}
            ><div onClick={() => this.labelFocusInst.focus()}><span className="error"></span>车牌号</div></InputItem>
            <InputItem
              // {...getFieldProps('phone')}
              type="phone"
              placeholder="请输入手机号"
              value={this.state.phone}
              onChange={(value) => this.setState({ phone: value })}
            >联系方式</InputItem>
            </List>

        <List className="date-picker-list">
        <DatePicker
          value={this.state.date}
          onChange={date => this.setState({ date })}
        >
          <List.Item arrow="horizontal">违规时间</List.Item>
        </DatePicker>
        </List>
        <TextareaItem
              title="违规详情"
              placeholder="请填写违规原因"
              value={this.state.wgyy}
              onChange={(value) => this.setState({ wgyy: value })}
              data-seed="logId"
              ref={el => this.autoFocusInst = el}
              autoHeight
            />


            <div className="formSubmit">
            <button className="submit" type="submit">
              提 交
            </button>
          </div>
            </form>
        </div>
    )
}
}

