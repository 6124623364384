import './clxs.css'
import { Route } from 'react-router'
import lrz from 'lrz';
import { BASE_URL ,DOWNFILEURL, Axios} from '../../../utils/url'
import {XSZ_URL,  CLCRSQ_URL, JHZ_URL, SFZ_URL, YKT_URL, XMHT_URL, LDHT_URL } from '../../../utils/fjyl'
import React, { Component } from 'react'
import { NavBar, Radio, Modal, NoticeBar, Icon, InputItem, List, Picker, ImagePicker, Toast, TextareaItem } from 'antd-mobile';
const RadioItem = Radio.RadioItem;
export default class ClxsTrEitder extends Component {
    constructor(props) {
        super(props);

        // const clxx = this.props.location.query.clxx;
        // console.log(clxx)
        //执行缓存数据
        // localStorage.setItem("clxx", JSON.stringify(clxx));
        // const location = this.props.location.pathname;
        // console.log(location)
        // const id = this.props.location.query.id;
        let isBgBool = false;


        this.state = {
            // syrlx:[clxx.syrlxbm],
            // cph: '',
            // clxx: clxx,
            cllcxx: [],
            modal: false,
            // id: id,
            isLoaded: false,
            djfisLoaded: false,
            zfdz: "",
            merOrderId: "",
            isPay: false,
            // dqzt: clxx.yxdqzt,
            isBg: isBgBool,
            value: "",
            modal2: false,


            fjList: '',
            // clsyr: clxx.cz,
            files: [],

            sfzfj: false,
            jhzfj: false,
            clcrsqdfj: false,
            yktfj: false,
            xmhtfj: false,
            ldhtfj: false,
            value: '',
            modal2: false,

            yktyl: false,
            clcrsqyl: false,
            sfzyl: false,
            jhzyl: false,
            xmhtyl: false,
            ldhtyl: false,
            bdtj:false,
            text: ""
        }
    }
    componentDidMount = () => {
        // this.state.clxx.cph = this.state.cph
        // console.log(this.state.syrlx)
        // this.handleFromChange()
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        console.log(userInfo);
        this.setState({
          yhid:userInfo.sfzh,
          yhxm:userInfo.yhxm,
          yhdwbm:userInfo.dwbm,
          yhdwmc:"",
          bgr: userInfo.ryxm,
          bgrsfzh: userInfo.sfzh,
        })
    }

//选择所有人类型
handleFromChange = (e) => {
    this.forceUpdate()

        console.log(this.state.syrlx)
        if (this.state.syrlx !== '') {
          const url = BASE_URL + 'brysfxmpzController/getCrzlxList';
          var params = new URLSearchParams();
          params.append('lxbm', this.state.syrlx);
          Axios({
            method: 'post',
            url: url,
            data: params
          }).then((res) => {
            console.log(res)
            if (res.status == 200) {
              if(this.state.syrlxText ==="员工及家属"){

                var jmcrz =[];
                 jmcrz.push(res.data.crzList[0])

                this.setState({
                  crzlxList: jmcrz,
                  fjList: res.data.fjList,
                  fjlxList: res.data.fjlxList
                })
              }else{

                this.setState({
                  crzlxList: res.data.crzList,
                  fjList: res.data.fjList,
                  fjlxList: res.data.fjlxList
                })
              }

            }

            console.log(this.state.fjList.length)
            var cc = this.state.fjList.length;
            const fjList = this.state.fjList;
            console.log(fjList)
            for (var a = 0; a < cc; a++) {
              console.log(fjList[a].fjlxbm);
              if (fjList[a].fjlxbm === "XSZ") {
                this.setState({
                  xsz: [fjList[a]],
                  xszList: [],
                })
                console.log(this.state.xsz);
              } else if (fjList[a].fjlxbm === "XMHT") {
                this.setState({
                  xmht: [fjList[a]],
                  xmhtList: []
                })
              } else if (fjList[a].fjlxbm === "CLCRSQD") {
                this.setState({
                  clcrsqd: [fjList[a]],
                  clcrsqdList: []
                })
              } else if (fjList[a].fjlxbm === "SFZ") {
                this.setState({
                  sfz: [fjList[a]],
                  sfzList: []
                })
              } else if (fjList[a].fjlxbm === "JHZ") {
                this.setState({
                  jhz: [fjList[a]],
                  jhzList: []
                })
              } else if (fjList[a].fjlxbm === "LDHT") {
                this.setState({
                  ldht: [fjList[a]],
                  ldhtList: []
                })
              } else if (fjList[a].fjlxbm === "YKT") {
                this.setState({
                  ykt: [fjList[a]],
                  yktList: []
                })
              }

            }
            // console.log(this.state)

          });


        }



  }

  onChangeImage = (files01, type, index) => {
    var str=[];
        for(let i=0;i<files01.length;i++){
            if(type!="remove"){
              lrz(files01[i].url, { quality: 0.1 }) .then((rst) => {
                console.log(rst)
                let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
                var arr = [{ orientation: 1, url: rst.base64, file: file }];;
                str.push(arr[0])
                this.setState({
                  xszList: str,
                })
              })
            }else{
              this.setState({
                xszList:files01,
            });
            }
          }


  }

    //车辆出入申请附件选择
    onChangeImageClcrsqd = (files01, type, index) => {
        // this.setState({
        //   clcrsqdList: e,
        // });
        if (type === 'add') {
            var str = [];
            for (let i = 0; i < files01.length; i++) {

                lrz(files01[i].url, { quality: 0.1 })
                    .then((rst) => {
                        // 处理成功会执行
                        console.log('压缩成功')

                        let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
                        console.log(file)
                        var arr = [{ orientation: 1, url: rst.base64, file: file }];
                        // arr.push(file);
                        str.push(arr[0])

                        this.setState({
                            clcrsqdList: str,
                        })
                    })
            }

        } else {
            this.setState({ clcrsqdList: '' })
        }
    }
    //身份证附件选择
    onChangeImageSfz = (files01, type, index) => {
        // this.setState({
        //   sfzList: e,
        // });
        if (type === 'add') {
            var str = [];
            for (let i = 0; i < files01.length; i++) {

                lrz(files01[i].url, { quality: 0.1 })
                    .then((rst) => {
                        // 处理成功会执行
                        console.log('压缩成功')




                        let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
                        console.log(file)
                        var arr = [{ orientation: 1, url: rst.base64, file: file }];
                        // arr.push(file);
                        str.push(arr[0])

                        this.setState({
                            sfzList: str,
                        })
                    })
            }

        } else {
            this.setState({ sfzList: '' })
        }
    }
    //一卡通附件选择
    onChangeImageYkt = (files01, type, index) => {
        // this.setState({
        //   yktList: e,
        // });
        if (type === 'add') {
            var str = [];
            for (let i = 0; i < files01.length; i++) {

                lrz(files01[i].url, { quality: 0.1 })
                    .then((rst) => {
                        // 处理成功会执行
                        console.log('压缩成功')




                        let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
                        console.log(file)
                        var arr = [{ orientation: 1, url: rst.base64, file: file }];
                        // arr.push(file);
                        str.push(arr[0])

                        this.setState({
                            yktList: str,
                        })
                    })
            }

        } else {
            this.setState({ yktList: '' })
        }
    }
    //项目合同附件选择
    onChangeImageXmht = (files01, type, index) => {
        // this.setState({
        //   xmhtList: e,
        // });
        if (type === 'add') {
            var str = [];
            for (let i = 0; i < files01.length; i++) {

                lrz(files01[i].url, { quality: 0.1 })
                    .then((rst) => {
                        // 处理成功会执行
                        console.log('压缩成功')




                        let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
                        console.log(file)
                        var arr = [{ orientation: 1, url: rst.base64, file: file }];
                        // arr.push(file);
                        str.push(arr[0])

                        this.setState({
                            xmhtList: str,
                        })
                    })
            }

        } else {
            this.setState({ xmthList: '' })
        }
    }
    //结婚证附件选择
    onChangeImageJhz = (files01, type, index) => {
        // this.setState({
        //   jhzList: e,
        // });
        if (type === 'add') {
            var str = [];
            for (let i = 0; i < files01.length; i++) {

                lrz(files01[i].url, { quality: 0.1 })
                    .then((rst) => {
                        // 处理成功会执行
                        console.log('压缩成功')




                        let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
                        console.log(file)
                        var arr = [{ orientation: 1, url: rst.base64, file: file }];
                        // arr.push(file);
                        str.push(arr[0])

                        this.setState({
                            jhzList: str,
                        })
                    })
            }

        } else {
            this.setState({ jhzList: '' })
        }
    }
    //劳动合同附件选择
    onChangeImageLdht = (files01, type, index) => {
        // this.setState({
        //   ldhtList: e,
        // });
        if (type === 'add') {
            var str = [];
            for (let i = 0; i < files01.length; i++) {

                lrz(files01[i].url, { quality: 0.1 })
                    .then((rst) => {
                        // 处理成功会执行
                        console.log('压缩成功')




                        let file = new File([rst.file], this.genID(16), { type: files01[i].file.type, lastModified: Date.now() });
                        console.log(file)
                        var arr = [{ orientation: 1, url: rst.base64, file: file }];
                        // arr.push(file);
                        str.push(arr[0])

                        this.setState({
                            ldhtList: str,
                        })
                    })
            }

        } else {
            this.setState({ ldhtList: '' })
        }
    }
    genID = (length) => {
      return Number(Math.random().toString().substr(3,length) + Date.now()).toString(36);
  }
  setMapList1 = () =>{
    const list = this.state.fjList
    let falg = false;
    let data = {}
    for (var item in list) {
        if (list[item].fjlxid == "6") {
            falg = true;
            data=list[item];
        }
    }
    if (falg) {
      var filesxsz = this.state.xszList;
      if (this.state.xsz != '' && this.state.xsz != undefined) {
        var files = [{
          url:XSZ_URL
        }]
        return(
          <div>
          <div>

          </div>
          <div className="scfj"><span style={{color:"red"}}>*</span>{data.fjlxmc}(<span onClick={()=>this.setState({ xszyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker
            files={filesxsz}
            onChange={this.onChangeImage}
            selectable={filesxsz.length < 1}
            // disableDelete="false"
            capture="camera"
          />
          <div className="line"></div>
          </div>
        )
      }
    }else{
      return null;
    }


  }

  setclcrsqMap1 = () => {
    const list = this.state.fjList
    let falg = false;
    let data = {}
    for (var item in list) {
        if (list[item].fjlxid == "1") {
            falg = true;
            data = list[item]
        }
    }
    if (falg) {
      const fileclcrsqd = this.state.clcrsqdList;
      if (this.state.clcrsqd != '' && this.state.clcrsqd != undefined) {
        var files = [{
          url:CLCRSQ_URL
        }]
        return(
          <div>



          <div className="scfj"><span style={{color:"red"}}>*</span>{data.fjlxmc}(<span onClick={()=>this.setState({ clcrsqyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker
            files={fileclcrsqd}
            onChange={this.onChangeImageClcrsqd}
            selectable={fileclcrsqd.length < 1}
            // disableDelete="false"
            capture="camera"
          />
          <span style={{
                            color:"#888",
                            padding: "15px 15px 9px"
                            }}>1、申请表单直接<span onClick={()=>this.download()} style={{
                            color:"#108ee9",}}>  下载</span></span>
                        <div style={{color:"#888",paddingLeft: "15px",paddingRight: "15px",paddingBottom: "10px"}}>2、宝石花主页-服务大厅表单-车辆出入申请表</div>
          <div className="line"></div>
          </div>
        )
      }
    }else{
      return null;
    }

  }
  genID = (length) => {
    return Number(Math.random().toString().substr(3,length) + Date.now()).toString(36);
}
download =()=>{
  window.open( DOWNFILEURL+'yktsqxx/download?fjlx=clsqdfj')
}

  setsfzMap1 = () => {
    const list = this.state.fjList
    let falg = false;
    let data = {}
    for (var item in list) {
        if (list[item].fjlxid == "2") {
            falg = true;
            data = list[item]
        }
    }
    if (falg) {
      const filesfz = this.state.sfzList;
      if (this.state.sfz != '' && this.state.sfz != undefined) {
        var files = [{
          url:SFZ_URL
        }]
        return(
          <div>
          <div style={{paddingTop: "10px"}}>

          </div>
          <div className="scfj"><span style={{color:"red"}}>*</span>{data.fjlxmc}(<span onClick={()=>this.setState({ sfzyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker
            files={filesfz}
            onChange={this.onChangeImageSfz}
            selectable={filesfz.length < 2}
            // disableDelete="false"
            capture="camera"
          />

          <div className="line"></div>
          </div>
        )
      }
    }else{
      return null;
    }

  }

  setyktMap1 = () => {
    const list = this.state.fjList
    let falg = false;
    let data = {}
    for (var item in list) {
      console.log(list[item].fjlxid)
        if (list[item].fjlxid == "5") {
            falg = true;
            data = list[item]
        }
    }
    if (falg) {
      const yktldht = this.state.yktList;
      if (this.state.ykt != '' && this.state.ykt != undefined) {
        var files = [{
          url:YKT_URL
        }
      ]
        return(
          <div>
          <div style={{paddingTop: "10px"}}>

          </div>
          <div className="scfj"><span style={{color:"red"}}>*</span>{data.fjlxmc}(<span onClick={()=>this.setState({ yktyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker

            files={yktldht}
            onChange={this.onChangeImageYkt}
            selectable={yktldht.length < 1}
            // disableDelete="false"
            capture="camera"
          />
          <div className="line"></div>


          </div>
        )
      }
    }else{
      return null;
    }

  }


  setxmhtMap1 = () => {
    const list = this.state.fjList
    let falg = false;
    let data = {}
    for (var item in list) {
        if (list[item].fjlxid == "8") {
            falg = true;
            data = list[item]
        }
    }
    if (falg) {
      const filexmht = this.state.xmhtList;
      if (this.state.xmht != '' && this.state.xmht != undefined) {
        var files = [{
          url:XMHT_URL
        }]
        return(
          <div>
          <div style={{paddingTop: "10px"}}>

          </div>
          <div className="scfj"><span style={{color:"red"}}>*</span>{data.fjlxmc}(<span onClick={()=>this.setState({ xmhtyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker
          files={filexmht}
          onChange={this.onChangeImageXmht}
          selectable={filexmht.length < 4}
          // disableDelete="false"
          capture="camera"
        />

        <div className="line"></div>
        </div>
        )
      }
    }else{
return null;
    }

  }


  setjhzMap1 = () => {
    const list = this.state.fjList
    let falg = false;
    let data = {}
    for (var item in list) {
        if (list[item].fjlxid == "3") {
            falg = true;
            data = list[item]
        }
    }
    if (falg) {
      const filejhz = this.state.jhzList;
      if (this.state.jhz != '' && this.state.jhz != undefined) {
        var files = [{
          url:JHZ_URL
        }]
        return(
          <div>
          <div style={{paddingTop: "10px"}}>

          </div>
          <div className="scfj">{data.fjlxmc}(<span onClick={()=>this.setState({ jhzyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker
              files={filejhz}
              onChange={this.onChangeImageJhz}
              selectable={filejhz.length < 1}
              // disableDelete="false"
              capture="camera"
            />
            <div className="line"></div>
          </div>
        )
      }
    }else{
      return null;
    }

  }

  setldhtMap1 = () => {
    const list = this.state.fjList
    let falg = false;
    let data = {}
    for (var item in list) {
        if (list[item].fjlxid == "4") {
            falg = true;
            data = list[item]
        }
    }
    if (falg) {
      const fileldht = this.state.ldhtList;
      if (this.state.ldht != '' && this.state.ldht != undefined) {
        var files = [{
          url:LDHT_URL
        }]
        return(
          <div>
          <div style={{paddingTop: "10px"}}>

          </div>
          <div className="scfj"><span style={{color:"red"}}>*</span>{data.fjlxmc}(<span onClick={()=>this.setState({ ldhtyl: true })} style={{
            color:"#108ee9",

          }}>查看样例</span>)</div>
          <ImagePicker
              files={fileldht}
              onChange={this.onChangeImageLdht}
              selectable={fileldht.length < 4}
              // disableDelete="false"
              capture="camera"
            />
            <div className="line"></div>
          </div>
        )
      }
    }else{
      return null;
    }

  }

  onClosexsz = ()=>{
    this.setState({
          xszyl: false
        });
  }
      onClosesfz = ()=>{
        this.setState({
              sfzyl: false
            });
      }
      onClosesfzfm = ()=>{
        this.setState({
              sfzyl: false
            });
      }
      onCloseclcrsq = ()=>{
        this.setState({
              clcrsqyl: false
            });
      }
      onCloseykt = ()=>{
        this.setState({
              yktyl: false
            });
      }
      onCloseygk = ()=>{
        this.setState({
              ygkyl: false
            });
      }
      onClosejhz = ()=>{
        this.setState({
              jhzyl: false
            });
      }
      onClosexmht = ()=>{
        this.setState({
              xmhtyl: false
            });
      }
      onCloseldht = ()=>{
        this.setState({
              ldhtyl: false
            });
      }

    //续费表单提交
    handleSubmit = e => {
        //阻止表单提交行为
        e.preventDefault()


        // 表单数据

        const { sqrid, bglxtext,  sfzList, yktList, jhzList, xmhtList, ldhtList, clcrsqdList, syrlxText } = this.state
        // const { mjkbh, sjcqqx, yhid, yhxm, yhdwbm, yhdwmc, sqid, zz, cllx, syrlx, syrlxbm, crzlx, crzbh, cz, cph, ckbh, jflb, sqr, sqdw, sfzh, sjh, jtdh, bgdh, yktbh, dwmc, sqbz, hyhdwbm } = this.state.clxx

        const url = BASE_URL + 'bsqjlController/save';
        let params = new FormData()
        params.append('syrlxmc', '');
        params.append('syrlxbm', '');
        params.append('crzlxmc', '');
        params.append('crzlxbm', '');
        params.append('clsyr', '');
        params.append('cph', '');
        params.append('cllxmc', '');
        params.append('cllxbm', '');
        params.append('sqr', '');
        params.append('sqrdw', '');
        params.append('sfzh', '');
        params.append('zz', '');
        params.append('sjh', this.state.xssjh);
        params.append('yktbh', '');
        params.append('zrr', '');
        params.append('jsdw', '');
        params.append('sqsm', '');
        params.append('sqrid', "");
        params.append('dwmc', '');
        params.append('dwbm', '');
        params.append('sqid', '');
        params.append('sjcqqx', '');
        params.append('crzbh', '');
        params.append('mjkbh', '');
        params.append('presqid', this.state.sqid);
        params.append('cph', this.state.cph);
        params.append('preyhid', this.state.yhid);
        params.append('preyhxm', this.state.yhxm);
        params.append('predwmc', this.state.yhdwmc);
        params.append('predwbm', this.state.yhdwbm);
        params.append('bgr', this.state.bgr);
        params.append('bgrsfzh', this.state.bgrsfzh);
        params.append('xssqid', this.state.xssqid);
        //xinzeng
        params.append('bglx', "续时申请");


        let fileinfo = []
    if (sfzList !== undefined) {
      // for (var i = 0; i < sfzList.length; i++) {
      //   params.append("sfzfjList", sfzList[i].file);
      // }
      if (sfzList.length !== 0) {
        for (let f of sfzList) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '2' });
        }
        // params.append('fileinfo', JSON.stringify(fileinfo));
    }
    }
    if (xmhtList !== undefined) {
      // for (var i = 0; i < xmhtList.length; i++) {
      //   params.append("xmhtfjList", xmhtList[i].file);
      // }
      if (xmhtList.length !== 0) {
        for (let f of xmhtList) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '8' });
        }
        // params.append('fileinfo', JSON.stringify(fileinfo));
    }
    }
    if (yktList !== undefined) {
      // for (var i = 0; i < yktList.length; i++) {
      //   params.append("yktfjList", yktList[i].file);
      // }
      if (yktList.length !== 0) {
        for (let f of yktList) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '5' });
        }
        // params.append('fileinfo', JSON.stringify(fileinfo));
    }
    }
    if (jhzList !== undefined) {
      // for (var i = 0; i < jhzList.length; i++) {
      //   params.append("jhzfjList", jhzList[i].file);
      // }
      if (jhzList.length !== 0) {
        for (let f of jhzList) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '3' });
        }
        // params.append('fileinfo', JSON.stringify(fileinfo));
    }
    }
    if (ldhtList !== undefined) {
      // for (var i = 0; i < ldhtList.length; i++) {
      //   params.append("ldhtfjList", ldhtList[i].file);
      // }
      if (ldhtList.length !== 0) {
        for (let f of ldhtList) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '4' });
        }
        // params.append('fileinfo', JSON.stringify(fileinfo));
    }
    }
    if (this.state.xszList !== undefined) {
      // for (var i = 0; i < xszList.length; i++) {
      //   params.append("xszfjList", xszList[i].file);
      // }
      if (this.state.xszList.length !== 0) {
        for (let f of this.state.xszList) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '6' });
        }
        // params.append('fileinfo', JSON.stringify(fileinfo));
    }
    }
    if (clcrsqdList !== undefined) {
      // for (var i = 0; i < clcrsqdList.length; i++) {
      //   params.append("clcrsqdfjList", clcrsqdList[i].file);
      //   console.log(clcrsqdList[i].file)
      // }
      if (clcrsqdList.length !== 0) {
        for (let f of clcrsqdList) {
            params.append("fjList", f.file);
            fileinfo.push({ name: f.file.name, type: '1' });
        }
        // params.append('fileinfo', JSON.stringify(fileinfo));
    }
    }
    params.append('fileinfo', JSON.stringify(fileinfo));


        if (this.state.jhz !== undefined && this.state.jhz[0].sfbt === "必填") {

            if (jhzList.length === 0) {
                Toast.info("请上传结婚证附件", 1, null, false)

            } else {
                params.append('jhzList', JSON.stringify(jhzList));
                this.state.jhzfj = true
            }

        } else {
            params.append('jhzList', JSON.stringify(jhzList));
            this.state.jhzfj = true
        }

        if (this.state.clcrsqd !== undefined && this.state.clcrsqd[0].sfbt === "必填") {
            if (clcrsqdList.length === 0) {
                Toast.info("请上传车辆出入申请单附件", 1, null, false)
            } else {
                params.append('clcrsqdList', JSON.stringify(clcrsqdList));
                this.state.clcrsqdfj = true
            }

        } else {
            params.append('clcrsqdList', JSON.stringify(clcrsqdList));
            this.state.clcrsqdfj = true
        }
        if (this.state.sfz !== undefined && this.state.sfz[0].sfbt === "必填") {

            if (sfzList.length === 0) {
                Toast.info("请上传身份证附件", 1, null, false)
            } else {
                params.append('sfzList', JSON.stringify(sfzList));
                this.state.sfzfj = true
            }

        } else {
            this.state.sfzfj = true
            params.append('sfzList', JSON.stringify(sfzList));
        }
        if (this.state.ykt !== undefined && this.state.ykt[0].sfbt === "必填") {

            if (yktList.length === 0) {
                Toast.info("请上传一卡通或员工卡附件", 1, null, false)
            } else {
                params.append('yktList', JSON.stringify(yktList));
                this.state.yktfj = true
            }

        } else {
            this.state.yktfj = true
            params.append('yktList', JSON.stringify(yktList));
        }
        if (this.state.xmht !== undefined && this.state.xmht[0].sfbt === "必填") {
            if (xmhtList.length === 0) {
                Toast.info("请上传项目合同附件", 1, null, false)

            } else {
                params.append('xmhtList', JSON.stringify(xmhtList));
                this.state.xmhtfj = true
            }

        } else {
            this.state.xmhtfj = true
            params.append('xmhtList', JSON.stringify(xmhtList));
        }
        if (this.state.ldht !== undefined && this.state.ldht[0].sfbt === "必填") {

            if (ldhtList.length === 0) {
                Toast.info("请上传劳动合同附件", 1, null, false)
            } else {
                this.state.ldhtfj = true
                params.append('ldhtList', JSON.stringify(ldhtList));
            }

        } else {
            this.state.ldhtfj = true
            params.append('ldhtList', JSON.stringify(ldhtList));
        }

let xsphone = false;
if(this.state.xssjh !==""){
  xsphone = true;
}
      if(this.state.bdtj){
        if (this.state.cph && this.state.jhzfj && this.state.sfzfj && this.state.yktfj && this.state.xmhtfj && this.state.ldhtfj  &&this.state.xssjh) {

          console.log("现在可以提交表单")
          Toast.loading("加载中...", 0, null, true);
          Axios({
              headers: { 'Content-Type': 'multipart/form-data' },
              method: 'post',
              url: url,
              data: params,
              //这部分非常重要，否则formdata会被转格式
              // transformRequest: [function () {
              //     return params;
              // }],
          }).then((res) => {
              console.log(res)
              Toast.hide();
              if (res.status == 200) {
                // Toast.info("保存成功")
                Toast.success('延期成功', 1);
                let _that = this;
                setTimeout( function(){
                  _that.props.history.go(-1)
                  }, 1000 );
            } else {
                // Toast.info("保存失败")
                Toast.fail('延期失败', 1);
            }


          });
      }else{
        Toast.info("请将表单填写完整")
      }
      }


    }



    checkXs = (e) => {
        const url = BASE_URL + 'bclcrsqbController/checkXs';
        var params = new URLSearchParams();
        params.append('cph', e);
        Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res)
            if (res.data.flag === true) {
                // Toast.info(res.data.msg,1,null,false)
                if (res.data.zzyxrq) {
                  var startdate = new Date();
                  var enddate = res.data.zzyxrq;
                  console.log(enddate)
                  //当前时间
                  var sDate = new Date(startdate).getTime();

                  console.log(sDate)
                  // console.log(stime);
                  //到期时间
                  // var eDate = new Date(enddate).getTime();
                  var eDate=new Date(enddate.replace(/-/g, "/")).getTime();
                  console.log(eDate)
                  var thisMothDays = 1000 * 3600 * 24 * 60;


                 var xsDate = eDate - sDate;
                //  xsDate = parseInt(xsDate)

                  if (eDate - sDate > 0) {
                    if (xsDate > thisMothDays) {
                      Toast.fail('请您于到期时间两个月内提交', 3, null, false);
                      this.setState({
                        cph: ''
                      })
                      return;
                    }else{
                      this.setState({
                        sqid:res.data.sqid,
                        cph:e,
                        syrlx:res.data.syrlx,
                        crzlx:res.data.crzlx,
                        bdtj:true
                    })
                    this.handleFromChange()
                    }

                  }else if(eDate < sDate){
                    this.setState({
                      sqid:res.data.sqid,
                      cph:e,
                      syrlx:res.data.syrlx,
                      crzlx:res.data.crzlx,
                      bdtj:true
                  })
                  this.handleFromChange()
                  }
                }

            }else{
              this.setState({
                cph: ''
              })
              Toast.info(res.data.msg)

            }

        });

    }



    render() {
        const entranceCllxOptions = this.state.cllxList
        // console.log(this.state)

        return (
            <div style={{ marginTop: ".89rem", height: "100%" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.push({
                      pathname: './main',
                      search: `?isNone='none'`
                  })}
                >单位车辆延期</NavBar>
                <List renderHeader={() => <div style={{marginTop:"10px",color:"#888",fontSize:"14px"}}></div>} className="popup-list">

                    <div>

                        <div>
                        <NoticeBar marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }}>
                        单位车辆延期申请提交后，预计三个工作日内审核完成，单位延期车辆必须是乙方单位的车辆
                        </NoticeBar>

                            <form onSubmit={this.handleSubmit}>

                                <InputItem
                                    type="text"
                                    placeholder="请输入他人车牌号"
                                    onChange={(value) => this.setState({ cph: value })}
                                    onBlur={this.checkXs}
                                    value={this.state.cph}
                                ><div>车牌号</div></InputItem>

                                <InputItem
                                    type="phone"
                                    placeholder="请输入续时手机号"
                                    value={this.state.xssjh}
                                    onChange={(value) => this.setState({ xssjh: value })}
                                >手机号码</InputItem>



                                <div className="scfj">上传附件</div>
                                {this.setMapList1()}
            {/* {this.setMapList()} */}
            <Modal
                    visible={ this.state.xszyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosexsz(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={XSZ_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>

            {this.setsfzMap1()}
            {/* {this.setsfzMap()} */}
            <Modal
                    visible={ this.state.sfzyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosesfz(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={SFZ_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>

            {this.setyktMap1()}
            {/* {this.setyktMap()} */}
            <Modal
                    visible={ this.state.yktyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onCloseykt(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={YKT_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>

            {this.setjhzMap1()}
            {/* {this.setjhzMap()} */}
            <Modal
                    visible={ this.state.jhzyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosejhz(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={JHZ_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
            {this.setxmhtMap1()}
            {/* {this.setxmhtMap()} */}
            <Modal
                    visible={ this.state.xmhtyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClosexmht(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={XMHT_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
            {this.setldhtMap1()}
            {/* {this.setldhtMap()} */}
            <Modal
                    visible={ this.state.ldhtyl }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onCloseldht(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={LDHT_URL}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>

                                <div className="formSubmit">
                                    <button className="submit" type="submit">
                                        申 请
                                    </button>
                                </div>
                            </form>
                        </div>





                    </div>
                </List>
            </div>


        )
    }
}
